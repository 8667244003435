// src/styles/ProgrammeStyle.js

import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import {
    Box,
    Button,
    Chip,
    Container,
    FormControl,
    Paper,
    TextField,
    Typography,
    IconButton
} from "@mui/material";

// Container principal
export const StyledContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(3),
    maxWidth: '100%',
    minHeight: '100vh',
    backgroundColor: alpha(theme.palette.background.default, 0.98)
}));

// Carte de programme
export const EnhancedProgrammeCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: theme.shadows[8],
    },
    borderRadius: '12px',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${alpha(theme.palette.divider, 0.1)}`
}));

// Titre du programme
export const ProgrammeTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    fontSize: '1.25rem',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden'
}));

// Description du programme
export const ProgrammeDescription = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    lineHeight: '1.5em',
    minHeight: '3em'
}));

// Section de programme
export const ProgrammeSection = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    borderRadius: theme.spacing(1),
    transition: 'background-color 0.2s ease',
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.06),
    }
}));

// Container pour les puces de routine
export const RoutineChips = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 0)
}));

// Bouton d'action
export const ActionButton = styled(IconButton)(({ theme }) => ({
    minWidth: 'unset',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
        transform: 'scale(1.1)',
    }
}));

// Puce de détail
export const DetailChip = styled(Chip)(({ theme }) => ({
    borderRadius: '8px',
    height: '32px',
    '& .MuiChip-label': {
        padding: '0 12px',
    },
    '& .MuiChip-icon': {
        fontSize: '1.2rem',
        marginLeft: '8px'
    },
    transition: 'all 0.2s ease',
    '&:hover': {
        transform: 'scale(1.05)'
    }
}));

// Champ de texte stylisé
export const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        transition: 'all 0.2s ease',
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
            }
        },
        '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px'
            }
        }
    }
}));

// Contrôle de formulaire pour les filtres
export const FilterFormControl = styled(FormControl)(({ theme }) => ({
    width: '100%',
    marginBottom: theme.spacing(3),
    '& .MuiOutlinedInput-root': {
        borderRadius: '8px'
    }
}));

// Container pour les puces
export const ChipContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    minHeight: '40px',
    padding: theme.spacing(1, 0)
}));

// Carte de routine
export const RoutineCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '12px',
    border: `1px solid ${theme.palette.divider}`,
    transition: 'all 0.2s ease',
    '&:hover': {
        boxShadow: theme.shadows[4],
        borderColor: alpha(theme.palette.primary.main, 0.2)
    }
}));

// Container de recherche
export const SearchContainer = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
        borderRadius: '12px',
        backgroundColor: theme.palette.background.paper,
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.02)
        }
    }
}));

// Sections supplémentaires pour améliorer l'interface
export const DialogHeader = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2)
}));

export const DialogFooter = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2)
}));

export const EmptyState = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '50vh',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    padding: theme.spacing(3)
}));