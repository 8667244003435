import React from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HairTypeSelector from "../components/HairTypeSelector";
import RoleSelector from "../components/dashboard/RoleSelector";

export const Overlay = ({ open, onClose }) => {
    if (!open) return null;

    return (
        <Box
            onClick={onClose}
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1200
            }}
        />
    );
};

export const UpdateUserBox = ({ open, onClose, currentUser, onChange, onUpdate }) => {
    if (!open) return null;

    const handleSubmit = (e) => {
        e.preventDefault();
        onUpdate();
    };

    const handleBoxClick = (e) => {
        e.stopPropagation();
    };

    return (
        <Box
            onClick={handleBoxClick}
            sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%',
                maxWidth: '600px',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                maxHeight: '90vh',
                overflowY: 'auto',
                zIndex: 1300
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h6">Mise à jour de l'utilisateur</Typography>
                <IconButton onClick={onClose} edge="end">
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
            >
                <TextField
                    margin="dense"
                    label="Prénom"
                    type="text"
                    fullWidth
                    name="firstName"
                    value={currentUser?.firstName || ''}
                    onChange={onChange}
                    variant="outlined"
                    sx={{ mb: 2 }}
                />
                <TextField
                    margin="dense"
                    label="Nom"
                    type="text"
                    fullWidth
                    name="lastName"
                    value={currentUser?.lastName || ''}
                    onChange={onChange}
                    variant="outlined"
                    sx={{ mb: 2 }}
                />
                <TextField
                    margin="dense"
                    label="Email"
                    type="email"
                    fullWidth
                    name="email"
                    value={currentUser?.email || ''}
                    onChange={onChange}
                    variant="outlined"
                    sx={{ mb: 2 }}
                />
                <TextField
                    margin="dense"
                    label="Lien de la photo de profil"
                    type="text"
                    fullWidth
                    name="profileImage"
                    value={currentUser?.profileImage || ''}
                    onChange={onChange}
                    variant="outlined"
                    sx={{ mb: 2 }}
                />

                <RoleSelector
                    value={currentUser?.role || 'user'}
                    onChange={(e) => {
                        onChange({
                            target: { name: 'role', value: e.target.value }
                        });
                    }}
                />

                <Box sx={{ mt: 2, mb: 1 }}>
                    <HairTypeSelector
                        value={currentUser?.typeDeCheveux || ''}
                        onChange={(e) => {
                            onChange({
                                target: { name: 'typeDeCheveux', value: e.target.value }
                            });
                        }}
                    />
                </Box>

                <TextField
                    margin="dense"
                    label="Âge"
                    type="number"
                    fullWidth
                    name="age"
                    value={currentUser?.age || ''}
                    onChange={onChange}
                    variant="outlined"
                    sx={{ mb: 2 }}
                    inputProps={{ min: 0 }}
                />

                <Box display="flex" justifyContent="flex-end" gap={1} mt={2}>
                    <Button onClick={onClose}>
                        Annuler
                    </Button>
                    <Button
                        onClick={onUpdate}
                        color="primary"
                    >
                        Mettre à jour
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export const CreateUserBox = ({ open, onClose, newUser, onChange, onCreate }) => {
    if (!open) return null;

    const handleBoxClick = (e) => {
        e.stopPropagation();
    };

    return (
        <Box
            onClick={handleBoxClick}
            sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%',
                maxWidth: '600px',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                maxHeight: '90vh',
                overflowY: 'auto',
                zIndex: 1300
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h6">Créer un nouvel utilisateur</Typography>
                <IconButton onClick={onClose} edge="end">
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box
                component="form"
                noValidate
            >
                <TextField
                    margin="dense"
                    label="Prénom"
                    type="text"
                    fullWidth
                    required
                    name="firstName"
                    value={newUser.firstName}
                    onChange={onChange}
                />
                <TextField
                    margin="dense"
                    label="Nom"
                    type="text"
                    fullWidth
                    required
                    name="lastName"
                    value={newUser.lastName}
                    onChange={onChange}
                />
                <TextField
                    margin="dense"
                    label="Âge"
                    type="number"
                    fullWidth
                    required
                    name="age"
                    value={newUser.age}
                    onChange={onChange}
                />
                <TextField
                    margin="dense"
                    label="Email"
                    type="email"
                    fullWidth
                    required
                    name="email"
                    value={newUser.email}
                    onChange={onChange}
                />
                <TextField
                    margin="dense"
                    label="Mot de Passe"
                    type="password"
                    fullWidth
                    required
                    name="mdp"
                    value={newUser.mdp}
                    onChange={onChange}
                />

                <Box sx={{ mt: 2, mb: 1 }}>
                    <HairTypeSelector
                        value={newUser.typeDeCheveux}
                        onChange={(e) => {
                            onChange({
                                target: { name: 'typeDeCheveux', value: e.target.value }
                            });
                        }}
                    />
                </Box>

                <RoleSelector
                    value={newUser.role}
                    onChange={(e) => {
                        onChange({
                            target: { name: 'role', value: e.target.value }
                        });
                    }}
                />

                <Box display="flex" justifyContent="flex-end" gap={1} mt={2}>
                    <Button onClick={onClose}>
                        Annuler
                    </Button>
                    <Button
                        onClick={onCreate}
                        color="primary"
                    >
                        Créer
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};