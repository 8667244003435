// src/components/HairTypeSelector.js

import React from 'react';
import {
    Select,
    InputLabel,
    Chip,
    Typography
} from '@mui/material';
import { HAIR_TYPES, getCategoryColor } from '../constants/hairTypes';
import {
    HairTypeSelect,
    HairTypeMenuItem,
    HairTypeBox,
    HairTypeName,
    HairTypeDescription,
    HairTypeInfoBox
} from '../styles/HairTypeStyle';

const HairTypeSelector = ({ value, onChange, label = "Type de cheveux" }) => {
    return (
        <HairTypeSelect fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                onChange={onChange}
                label={label}
                renderValue={(selected) => {
                    const hairType = HAIR_TYPES.find(type => type.id === selected);
                    if (!hairType) return "Tous les types";

                    return (
                        <HairTypeInfoBox>
                            <Typography>{hairType.name}</Typography>
                            {hairType.category !== 'Mixte' && (
                                <Chip
                                    size="small"
                                    label={hairType.category}
                                    color={getCategoryColor(hairType.category)}
                                />
                            )}
                        </HairTypeInfoBox>
                    );
                }}
            >
                <HairTypeMenuItem value="">
                    <Typography variant="subtitle1">Tous les types</Typography>
                </HairTypeMenuItem>
                {HAIR_TYPES.map((type) => (
                    <HairTypeMenuItem key={type.id} value={type.id}>
                        <HairTypeBox>
                            <HairTypeName>
                                {type.name}
                                {type.category !== 'Mixte' && (
                                    <Chip
                                        size="small"
                                        label={type.category}
                                        color={getCategoryColor(type.category)}
                                    />
                                )}
                            </HairTypeName>
                            <HairTypeDescription>
                                {type.description}
                            </HairTypeDescription>
                        </HairTypeBox>
                    </HairTypeMenuItem>
                ))}
            </Select>
        </HairTypeSelect>
    );
};

export default HairTypeSelector;