import React from 'react';
import { Typography, Paper, Divider } from '@mui/material';
import { Box } from '@mui/system';

const PrivacyPage = () => {
    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            padding: '24px',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#f4f5f7',
        }}>
            <Box sx={{
                maxWidth: '800px',
                width: '100%',
            }}>
                <Typography
                    variant="h4"
                    sx={{
                        textAlign: 'center',
                        marginBottom: '30px',
                        color: '#6A0D47',
                        fontWeight: 'bold'
                    }}
                >
                    Politique de confidentialité de Groomed Hair
                </Typography>

                <Paper sx={{
                    padding: '30px',
                    backgroundColor: '#ffffff',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    marginBottom: '24px'
                }}>
                    <Typography
                        paragraph
                        variant="body1"
                        sx={{
                            fontSize: '1rem',
                            lineHeight: 1.7
                        }}
                    >
                        Chez Groomed Hair, nous nous engageons à protéger et respecter votre vie privée. Cette politique de confidentialité explique comment nous
                        utilisons et protégeons les informations que vous nous fournissez lorsque vous utilisez notre site web ou notre application mobile.
                    </Typography>

                    <Divider sx={{ margin: '20px 0' }} />

                    <Typography
                        variant="h5"
                        sx={{
                            marginBottom: '16px',
                            color: '#6A0D47',
                            fontWeight: '600'
                        }}
                    >
                        Collecte et utilisation des informations
                    </Typography>
                    <Typography
                        paragraph
                        sx={{
                            marginBottom: '16px',
                            fontSize: '1rem',
                            lineHeight: 1.7
                        }}
                    >
                        Nous recueillons des informations personnelles telles que votre nom, votre adresse e-mail et vos préférences lorsque vous créez un compte
                        sur Groomed Hair. Ces informations sont utilisées pour personnaliser votre expérience utilisateur et améliorer nos services.
                    </Typography>
                    <Typography
                        paragraph
                        sx={{
                            marginBottom: '16px',
                            fontSize: '1rem',
                            lineHeight: 1.7
                        }}
                    >
                        Nous utilisons également des cookies et des technologies similaires pour suivre votre activité sur notre site et recueillir des informations
                        sur votre comportement en ligne. Cela nous aide à fournir un contenu personnalisé et à analyser la performance de notre site.
                    </Typography>

                    <Divider sx={{ margin: '20px 0' }} />

                    <Typography
                        variant="h5"
                        sx={{
                            marginBottom: '16px',
                            color: '#6A0D47',
                            fontWeight: '600'
                        }}
                    >
                        Partage des informations
                    </Typography>
                    <Typography
                        paragraph
                        sx={{
                            marginBottom: '16px',
                            fontSize: '1rem',
                            lineHeight: 1.7
                        }}
                    >
                        Nous ne partageons pas vos informations personnelles avec des tiers sans votre consentement, sauf si cela est nécessaire pour fournir nos services
                        ou si nous sommes légalement tenus de le faire.
                    </Typography>

                    <Divider sx={{ margin: '20px 0' }} />

                    <Typography
                        variant="h5"
                        sx={{
                            marginBottom: '16px',
                            color: '#6A0D47',
                            fontWeight: '600'
                        }}
                    >
                        Sécurité des données
                    </Typography>
                    <Typography
                        paragraph
                        sx={{
                            marginBottom: '16px',
                            fontSize: '1rem',
                            lineHeight: 1.7
                        }}
                    >
                        Nous prenons des mesures techniques et organisationnelles pour protéger vos informations personnelles contre tout accès non autorisé, utilisation
                        abusive ou divulgation. Cependant, veuillez noter qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée.
                    </Typography>

                    <Divider sx={{ margin: '20px 0' }} />

                    <Typography
                        variant="h5"
                        sx={{
                            marginBottom: '16px',
                            color: '#6A0D47',
                            fontWeight: '600'
                        }}
                    >
                        Vos droits
                    </Typography>
                    <Typography
                        paragraph
                        sx={{
                            marginBottom: '16px',
                            fontSize: '1rem',
                            lineHeight: 1.7
                        }}
                    >
                        Vous avez le droit d'accéder à vos informations personnelles, de les corriger ou de les supprimer. Vous pouvez également vous opposer
                        au traitement de vos données ou demander la limitation de leur utilisation.
                    </Typography>

                    <Divider sx={{ margin: '20px 0' }} />

                    <Typography
                        variant="h5"
                        sx={{
                            marginBottom: '16px',
                            color: '#6A0D47',
                            fontWeight: '600'
                        }}
                    >
                        Modifications de la politique
                    </Typography>
                    <Typography
                        paragraph
                        sx={{
                            marginBottom: '16px',
                            fontSize: '1rem',
                            lineHeight: 1.7
                        }}
                    >
                        Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page
                        et, si les modifications sont importantes, nous vous fournirons un avis plus visible.
                    </Typography>

                    <Typography
                        paragraph
                        sx={{
                            fontSize: '1rem',
                            lineHeight: 1.7,
                            color: '#666',
                            marginTop: '30px',
                            fontStyle: 'italic'
                        }}
                    >
                        Si vous avez des questions ou des préoccupations concernant notre politique de confidentialité, n'hésitez pas à nous contacter à
                        l'adresse privacy@groomedhair.com ou via notre formulaire de contact.
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: '0.875rem',
                            color: '#888',
                            marginTop: '20px',
                            textAlign: 'center'
                        }}
                    >
                        Dernière mise à jour : Novembre 2024
                    </Typography>
                </Paper>
            </Box>
        </Box>
    );
};

export default PrivacyPage;