import React, { useState } from 'react';
import { Box, Paper, Typography, Button, TextField,
    Card, CardContent, Dialog, DialogTitle, DialogContent,
    DialogActions, Grid, Avatar, Chip, Select, MenuItem, FormControl, InputLabel,
    AppBar, Toolbar, Fab
} from '@mui/material';
import { Add as AddIcon, Send as SendIcon, Update as UpdateIcon,
    NewReleases as NewReleasesIcon, AdminPanelSettings as AdminIcon,
    SupervisorAccount as ModeratorIcon, CloudUpload as CloudUploadIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useQuery, useMutation } from '@apollo/client';
import { ME_QUERY, GET_ALL_ANNONCES } from '../../graphql/queries';
import { CREATE_ANNONCE } from '../../graphql/mutations';
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import AnnouncementsSection from './AnnouncementsSection';

// Styles personnalisés
const StyledContainer = styled(Box)(({ theme }) => ({
    maxWidth: 1200,
    margin: '0 auto',
    padding: theme.spacing(3),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: '#fff'
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const MediaPreview = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: 300,
    height: 150,
    margin: '0 auto',
    borderRadius: theme.shape.borderRadius,
    border: `1px dashed ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[100],
    position: 'relative',
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    '&:hover .remove-media': {
        opacity: 1
    }
}));

const RemoveMediaButton = styled(Button)(({ theme }) => ({
    position: 'absolute',
    top: 5,
    right: 5,
    minWidth: 'auto',
    padding: '4px',
    opacity: 0,
    transition: 'opacity 0.2s',
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
        backgroundColor: theme.palette.error.dark,
    }
}));

const Annonce = () => {
    const { data: userData } = useQuery(ME_QUERY);
    const userRole = userData?.me?.user?.role;

    const [openDialog, setOpenDialog] = useState(false);
    const [currentAnnonce, setCurrentAnnonce] = useState({
        title: '',
        content: '',
        important: false,
        media: '',
        mediaPreview: null,
        type: userRole === 'admin' ? 'update' : 'content'
    });

    // Types d'annonces selon le rôle
    const annonceTypes = {
        admin: [
            { value: 'update', label: 'Mise à jour de l\'application', icon: <UpdateIcon /> },
            { value: 'feature', label: 'Nouvelle fonctionnalité', icon: <NewReleasesIcon /> }
        ],
        moderator: [
            { value: 'content', label: 'Nouveau contenu', icon: <AddIcon /> },
            { value: 'routine', label: 'Nouvelle routine', icon: <FitnessCenterIcon /> },
            { value: 'product', label: 'Nouveau produit', icon: <ShoppingBasketIcon /> }
        ]
    };

    const compressImage = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const MAX_WIDTH = 800;
                    const MAX_HEIGHT = 600;
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);

                    const compressedBase64 = canvas.toDataURL('image/jpeg', 0.7);
                    resolve(compressedBase64);
                };
            };
        });
    };

    const handleMediaChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const compressedBase64 = await compressImage(file);
                setCurrentAnnonce(prev => ({
                    ...prev,
                    media: compressedBase64,
                    mediaPreview: compressedBase64
                }));
            } catch (error) {
                console.error('Error compressing image:', error);
            }
        }
    };

    const handleRemoveMedia = () => {
        setCurrentAnnonce(prev => ({
            ...prev,
            media: '',
            mediaPreview: null
        }));
    };

    const [createAnnonce] = useMutation(CREATE_ANNONCE, {
        refetchQueries: [{ query: GET_ALL_ANNONCES }],
        onCompleted: () => {
            setOpenDialog(false);
            setCurrentAnnonce({
                title: '',
                content: '',
                important: false,
                media: '',
                mediaPreview: null,
                type: userRole === 'admin' ? 'update' : 'content'
            });
        },
        onError: (error) => {
            console.error('Error creating announcement:', error);
        }
    });

    const handleCreateAnnonce = () => {
        setCurrentAnnonce({
            title: '',
            content: '',
            important: false,
            media: '',
            mediaPreview: null,
            type: userRole === 'admin' ? 'update' : 'content'
        });
        setOpenDialog(true);
    };

    const handlePublishAnnonce = async () => {
        if (currentAnnonce.title && currentAnnonce.content) {
            try {
                console.log('Publishing annonce with data:', {
                    title: currentAnnonce.title,
                    description: currentAnnonce.content,
                    media: currentAnnonce.media || '',
                });

                await createAnnonce({
                    variables: {
                        title: currentAnnonce.title,
                        description: currentAnnonce.content,
                        media: currentAnnonce.media || '',
                    }
                });
            } catch (error) {
                console.error('Error in handlePublishAnnonce:', error);
            }
        }
    };

    return (
        <div>
            <Toolbar />
            <div className='Title'>
                <Box display="flex" justifyContent="space-between" alignItems={'center'}>
                    <h2>Liste des annonces</h2>
                    <Fab color="primary" aria-label="add" onClick={handleCreateAnnonce} sx={{ mt: -2 }}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>
            {/* <AppBar position="static" color="transparent" elevation={0} sx={{ mb: 4 }}>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
                        Annonces
                    </Typography>
                    <Fab
                        color="primary"
                        variant="extended"
                        onClick={handleCreateAnnonce}
                        sx={{ gap: 1 }}
                    >
                        <AddIcon />
                        Nouvelle Annonce
                    </Fab>
                </Toolbar>
            </AppBar> */}

            <StyledPaper>
                <AnnouncementsSection />
            </StyledPaper>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="md">
                <DialogTitle>
                    {userRole === 'admin' ?
                        "Créer une annonce de mise à jour" :
                        "Créer une annonce de contenu"
                    }
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel>Type d'annonce</InputLabel>
                            <Select
                                value={currentAnnonce.type}
                                label="Type d'annonce"
                                onChange={(e) => setCurrentAnnonce({
                                    ...currentAnnonce,
                                    type: e.target.value
                                })}
                            >
                                {annonceTypes[userRole]?.map((type) => (
                                    <MenuItem key={type.value} value={type.value}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            {type.icon}
                                            <Typography>{type.label}</Typography>
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            label="Titre de l'annonce"
                            fullWidth
                            value={currentAnnonce.title}
                            onChange={(e) => setCurrentAnnonce({
                                ...currentAnnonce,
                                title: e.target.value
                            })}
                        />

                        <TextField
                            label="Contenu de l'annonce"
                            fullWidth
                            multiline
                            rows={4}
                            value={currentAnnonce.content}
                            onChange={(e) => setCurrentAnnonce({
                                ...currentAnnonce,
                                content: e.target.value
                            })}
                        />

                        <Box>
                            <Button
                                component="label"
                                variant="outlined"
                                startIcon={<CloudUploadIcon />}
                            >
                                {currentAnnonce.media ? 'Changer le média' : 'Ajouter un média'}
                                <VisuallyHiddenInput
                                    type="file"
                                    accept="image/*"
                                    onChange={handleMediaChange}
                                />
                            </Button>

                            {currentAnnonce.mediaPreview && (
                                <MediaPreview>
                                    <img
                                        src={currentAnnonce.mediaPreview}
                                        alt="Aperçu"
                                    />
                                    <RemoveMediaButton
                                        className="remove-media"
                                        onClick={handleRemoveMedia}
                                        size="small"
                                    >
                                        ×
                                    </RemoveMediaButton>
                                </MediaPreview>
                            )}
                        </Box>

                        <Chip
                            label="Marquer comme important"
                            color={currentAnnonce.important ? "error" : "default"}
                            onClick={() => setCurrentAnnonce({
                                ...currentAnnonce,
                                important: !currentAnnonce.important
                            })}
                            sx={{ width: 'fit-content', cursor: 'pointer' }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Annuler</Button>
                    <Button
                        variant="contained"
                        onClick={handlePublishAnnonce}
                        startIcon={<SendIcon />}
                        disabled={!currentAnnonce.title || !currentAnnonce.content}
                    >
                        Publier
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Annonce;