import React, {useState, useMemo, useEffect} from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
    Box, CssBaseline, AppBar, Toolbar, List, Typography, ListItem, ListItemIcon,
    ListItemText, IconButton, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent,
    TextField, DialogActions, Drawer, Avatar, Menu, MenuItem, CircularProgress,
    Fab, TablePagination, Chip
} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import MessageIcon from '@mui/icons-material/Message';
import CampaignIcon from '@mui/icons-material/Campaign';
import EventNoteIcon from '@mui/icons-material/EventNote';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {GET_ALL_USERS, DELETE_USER_MUTATION, UPDATE_USER_MUTATION, CREATE_USER_MUTATION
} from '../../graphql/mutations';
import { ME_QUERY } from '../../graphql/queries';
import ChatApp from './ChatApp ';
import Messagerie from './Messagerie';
import Produits from './Products';
import Routines from './Routine';
import Annonce from './Annonce';
import Programmes from './Programmes';
import logo from "../../assets/logo.png";
import Statistique from "./Statistique";
import AppsUsage from './Apps-usage';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import InsightsIcon from '@mui/icons-material/Insights';
import ResizableDrawer from "./ResizableDrawer";
import '../../styles/Dashboard.css';
import {HAIR_TYPES} from "../../constants/hairTypes";
import HairTypeSelector from "../HairTypeSelector";
import RoleSelector, {USER_ROLES} from "./RoleSelector";
import {getRoleColor, getRoleLabel} from "../../constants/roleUtils";
import { UpdateUserBox, CreateUserBox, Overlay } from '../UserForms';




const theme = createTheme({
    palette: {
        primary: {
            light: '#63a4fff',
            main: '#6A0D47',
            dark: '#F885CC',
            contrastText: '#ecfad8',
        },
        secondary: {
            light: '#F885CC',
            main: '#F885CC',
            dark: '#c49000',
            contrastText: '#6d4c41',
        },
        background: {
            default: '#f4f5f7',
        },
    },
});


const Dashboard = () => {
    const navigate = useNavigate();
    const [activeContent, setActiveContent] = useState('users');
    const [openDialog, setOpenDialog] = useState(false);
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [drawerWidth, setDrawerWidth] = useState(250);
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [profileImageFile, setProfileImageFile] = useState(null); // Ajout de l'état pour l'image



    const [currentUser, setCurrentUser] = useState({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        profileImage: '',
        age: '',
        typeDeCheveux: '',
        role: 'user'
    });

    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [newUser, setNewUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mdp: '',
        age: '',
        typeDeCheveux: '',
        role: 'user'
    });

    // GraphQL Queries and Mutations
    const { loading, error, data, refetch } = useQuery(GET_ALL_USERS);
    const { data: userData, loading: userLoading } = useQuery(ME_QUERY);

    const [deleteUser] = useMutation(DELETE_USER_MUTATION, {
        onCompleted: () => refetch()
    });

    const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
        onCompleted: () => {
            refetch();
            setOpenDialog(false);
        }
    });

    const [createUser] = useMutation(CREATE_USER_MUTATION, {
        onCompleted: () => {
            refetch();
            setOpenCreateDialog(false);
            setNewUser({ firstName: '', lastName: '', email: '', mdp: '', age: '', typeDeCheveux: '', role: 'user' });
        }
    });

    // Handlers for user management

    const handleOpenCreateDialog = () => setOpenCreateDialog(true);

    const handleCloseCreateDialog = () => setOpenCreateDialog(false);

    // Modifiez la fonction handleCreateUser pour garantir que age est toujours un nombre
    const handleCreateUser = async () => {
        try {
            // Validation des données
            const validationErrors = validateUserData(newUser);

            if (validationErrors.length > 0) {
                throw new Error(validationErrors.join('\n'));
            }

            // Vérification spécifique pour l'âge
            if (!newUser.age) {
                throw new Error("L'âge est obligatoire");
            }

            const age = parseInt(newUser.age);
            if (isNaN(age) || age < 0 || age > 120) {
                throw new Error("L'âge doit être un nombre valide entre 0 et 120");
            }

            // Préparation des données pour la mutation
            const userInput = {
                firstName: newUser.firstName.trim(),
                lastName: newUser.lastName.trim(),
                email: newUser.email.trim().toLowerCase(),
                mdp: newUser.mdp,
                typeDeCheveux: newUser.typeDeCheveux,
                age: age, // On s'assure que age est bien un nombre
                role: newUser.role || 'user'
            };

            // Exécution de la mutation
            const { data } = await createUser({
                variables: userInput,
                refetchQueries: [{ query: GET_ALL_USERS }]
            });

            if (data?.createUser) {
                setNewUser({
                    firstName: '',
                    lastName: '',
                    email: '',
                    mdp: '',
                    age: '',
                    typeDeCheveux: '',
                    role: 'user'
                });
                setOpenCreateDialog(false);
                alert('Utilisateur créé avec succès !');
            }
        } catch (error) {
            let errorMessage = "Une erreur est survenue lors de la création de l'utilisateur.";

            if (error.message.includes('duplicate key error')) {
                errorMessage = "Un utilisateur avec cet email existe déjà.";
            } else if (error.message.includes('\n')) {
                errorMessage = error.message;
            } else if (error.graphQLErrors?.length > 0) {
                errorMessage = error.graphQLErrors[0].message;
            }

            alert(errorMessage);
            console.error('Erreur détaillée:', error);
        }
    };

// Modifiez également la fonction de validation
    const validateUserData = (userData) => {
        const errors = [];

        if (!userData.firstName?.trim()) {
            errors.push("Le prénom est requis");
        }

        if (!userData.lastName?.trim()) {
            errors.push("Le nom est requis");
        }

        if (!userData.email?.trim()) {
            errors.push("L'email est requis");
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userData.email)) {
            errors.push("Format d'email invalide");
        }

        if (!userData.mdp?.trim()) {
            errors.push("Le mot de passe est requis");
        } else if (userData.mdp.length < 6) {
            errors.push("Le mot de passe doit contenir au moins 6 caractères");
        }

        if (!userData.typeDeCheveux) {
            errors.push("Le type de cheveux est requis");
        }

        // Validation plus stricte de l'âge
        if (!userData.age) {
            errors.push("L'âge est requis");
        } else {
            const age = parseInt(userData.age);
            if (isNaN(age) || age < 0 || age > 120) {
                errors.push("L'âge doit être un nombre valide entre 0 et 120");
            }
        }

        return errors;
    };

    const handleNewUserChange = (e) => setNewUser({ ...newUser, [e.target.name]: e.target.value });

    const handleShowStats = () => setActiveContent('stats');

    const handleShowAppUsage = () => setActiveContent('appUsage');

    const handleDelete = async (id) => {
        try {
            await deleteUser({ variables: { id } });
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const handleOpenDialog = (user) => {
        setCurrentUser({
            ...user,
            age: user.age || ''
        });
        setOpenDialog(true);
    };


    const handleCloseDialog = () => setOpenDialog(false);

    const uploadImageToYourService = async (file) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            // Remplacez cette URL par votre endpoint de téléchargement
            const response = await fetch('VOTRE_URL_DE_TELECHARGEMENT', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Erreur lors du téléchargement');
            }

            const data = await response.json();
            return data.imageUrl; // L'URL de l'image téléchargée
        } catch (error) {
            console.error('Erreur lors du téléchargement:', error);
            throw error;
        }
    };

    const handleFileChange = (file) => {
        setProfileImageFile(file);
    };


    const handleUpdate = async () => {
        try {
            const age = parseInt(currentUser.age, 10);
            if (isNaN(age) || age < 0) {
                throw new Error("L'âge doit être un nombre positif");
            }

            let imageUrl = currentUser.profileImage;

            const variables = {
                id: currentUser.id,
                firstName: currentUser.firstName.trim(),
                lastName: currentUser.lastName.trim(),
                email: currentUser.email.trim(),
                profileImage: imageUrl,
                typeDeCheveux: currentUser.typeDeCheveux,
                age: age,
                role: currentUser.role || 'user'
            };

            // Exécutez la mutation avec une gestion explicite du cache
            const result = await updateUser({
                variables,
                update: (cache, { data: { updateUser } }) => {
                    // 1. Mise à jour du cache pour ME_QUERY
                    try {
                        const existingMeData = cache.readQuery({ query: ME_QUERY });
                        if (existingMeData?.me?.user) {
                            cache.writeQuery({
                                query: ME_QUERY,
                                data: {
                                    me: {
                                        ...existingMeData.me,
                                        user: {
                                            ...existingMeData.me.user,
                                            ...updateUser
                                        }
                                    }
                                }
                            });
                        }
                    } catch (e) {
                        console.error("Erreur lors de la mise à jour du cache ME_QUERY:", e);
                    }

                    // 2. Mise à jour du cache pour GET_ALL_USERS
                    try {
                        const existingUsersData = cache.readQuery({ query: GET_ALL_USERS });
                        if (existingUsersData?.getAllUsers) {
                            const updatedUsers = existingUsersData.getAllUsers.map(user =>
                                user.id === updateUser.id ? { ...user, ...updateUser } : user
                            );

                            cache.writeQuery({
                                query: GET_ALL_USERS,
                                data: {
                                    getAllUsers: updatedUsers
                                }
                            });
                        }
                    } catch (e) {
                        console.error("Erreur lors de la mise à jour du cache GET_ALL_USERS:", e);
                    }
                },
                // Force le rafraîchissement des données
                refetchQueries: [
                    { query: GET_ALL_USERS },
                    { query: ME_QUERY }
                ],
                awaitRefetchQueries: true // Attend que les requêtes soient rafraîchies
            });

            if (result.data) {
                handleCloseDialog();
                // Force un rafraîchissement des données après la fermeture de la modal
                await refetch();
            }
        } catch (error) {
            console.error('Error updating user:', error);
            alert(error.message || 'Une erreur est survenue lors de la mise à jour');
        }
    };

    const handleChange = (e) => {
        setCurrentUser(prev => ({
            ...prev,
            [e.target.name]: e.target.name === 'age'
                ? e.target.value === '' ? '' : parseInt(e.target.value, 10)
                : e.target.value
        }));
    };


    // Navigation handlers
    const handleProfileMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);
    const handleLogout = () => {
        handleMenuClose();
        localStorage.removeItem('token');
        navigate('/');
    };

    // Filtered users with search
    const filteredUsers = useMemo(() => {
        if (!data?.getAllUsers) return [];
        return data.getAllUsers.filter((user) =>
            user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [data, searchQuery]);

    // Paginated users
    const paginatedUsers = useMemo(() => {
        return filteredUsers.slice(
            page * rowsPerPage,
            (page * rowsPerPage) + rowsPerPage
        );
    }, [filteredUsers, page, rowsPerPage]);

    if (loading || userLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography color="error">
                    Une erreur est survenue: {error.message}
                </Typography>
            </Box>
        );
    }



    // User Management Component
    const UserManagement = () => (
        <>
            <Toolbar />
            <div className='Title'>
                <Box display="flex" justifyContent="space-between" alignItems={'center'}>
                    <h2>Liste des utilisateurs</h2>
                    <Fab color="primary" aria-label="add" onClick={handleOpenCreateDialog}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>

            {/* <div className='Title'>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4" component="h2" sx={{
                        mb: 3,
                        color: theme.palette.primary.main,
                        fontWeight: 'bold'
                    }}>
                        Liste des utilisateurs
                    </Typography>
                    <Fab color="primary" aria-label="add" onClick={handleOpenCreateDialog}>
                        <AddIcon />
                    </Fab>
                </Box>
            </div> */}
            <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: 'center',
                width: '90%',
                margin: 'auto',
                paddingBottom: '15px',
                paddingTop: '35px'
            }}>
                <TextField
                    id="search"
                    label="Filtrer par nom ou email"
                    type="search"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{ mb: 2 }}
                />
            </Box>
            <div className="dashboard-container">
                <TableContainer component={Paper}>
                    <Table className="user-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">ID</TableCell>
                                <TableCell align="center">Photo de Profil</TableCell>
                                <TableCell align="center">Nom</TableCell>
                                <TableCell align="center">Prénom</TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">Rôle</TableCell>
                                <TableCell align="center">Type de Cheveux</TableCell>
                                <TableCell align="center">Âge</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedUsers.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell align="center">{user.id}</TableCell>
                                    <TableCell align="center">
                                        <Box display="flex" justifyContent="center">
                                            {console.log("User profile image:", user.profileImage)}
                                            <Avatar
                                                src={user.profileImage || "/default-profile.png"}
                                                alt={`${user.firstName} ${user.lastName}`}
                                                sx={{ width: 56, height: 56 }}
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">{user.lastName}</TableCell>
                                    <TableCell align="center">{user.firstName}</TableCell>
                                    <TableCell align="center">{user.email}</TableCell>
                                    <TableCell align="center">
                                        <Box display="flex" justifyContent="center">
                                            <Chip
                                                label={getRoleLabel(user.role)}
                                                color={getRoleColor(user.role)}
                                                size="small"
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center">
                                        {HAIR_TYPES.find(type => type.id === user.typeDeCheveux)?.name
                                            || user.typeDeCheveux || 'Non spécifié'}
                                    </TableCell>
                                    <TableCell align="center">{user.age || 'Non spécifié'}</TableCell>
                                    <TableCell align="center">
                                        <Box display="flex" justifyContent="center" gap={1}>
                                            <IconButton color="primary" onClick={() => handleOpenDialog(user)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton color="secondary" onClick={() => handleDelete(user.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={filteredUsers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10));
                        setPage(0);
                    }}
                />
            </div>

            {/* Update User Dialog */}
            <Overlay open={openDialog} onClose={handleCloseDialog} />
            <UpdateUserBox
                open={openDialog}
                onClose={handleCloseDialog}
                currentUser={currentUser}
                onChange={handleChange}
                onUpdate={handleUpdate}
                onFileChange={handleFileChange}
                profileImageFile={profileImageFile}
            />

            <Overlay open={openCreateDialog} onClose={handleCloseCreateDialog} />
            <CreateUserBox
                open={openCreateDialog}
                onClose={handleCloseCreateDialog}
                newUser={newUser}
                onChange={handleNewUserChange}
                onCreate={handleCreateUser}
            />
        </>
    );

    // Determine which content to show
    let ContentComponent;
    switch (activeContent) {
        case 'users':
            ContentComponent = UserManagement;
            break;
        case 'produits':
            ContentComponent = Produits;
            break;
        case 'routines':
            ContentComponent = Routines;
            break;
        case 'messages':
            ContentComponent = ChatApp;
            break;
        case 'annonces':
            ContentComponent = Annonce;
            break;
        case 'programmes':
            ContentComponent = Programmes;
            break;
        case 'stats':
            ContentComponent = Statistique;
            break;
        case 'appUsage':
            ContentComponent = AppsUsage;
            break;
        default:
            ContentComponent = UserManagement;
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
                    <Toolbar>
                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                            Mon Dashboard
                        </Typography>
                        <IconButton
                            edge="end"
                            aria-label="compte utilisateur"
                            aria-controls="primary-account-menu"
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <Avatar src={userData?.me?.user?.profileImage || "/default-profile.png"} />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                <Menu
                    anchorEl={anchorEl}
                    id="primary-account-menu"
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={() => navigate('/profile')}>Profil</MenuItem>
                    <MenuItem onClick={handleLogout}>Déconnexion</MenuItem>
                </Menu>

                <ResizableDrawer
                    drawerWidth={drawerWidth}
                    setDrawerWidth={setDrawerWidth}
                    isOpen={isDrawerOpen}
                    handleDrawerClose={() => setIsDrawerOpen(!isDrawerOpen)}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                        <img src={logo} alt="Logo Groomed Hair" style={{ width: '150px', height: 'auto' }} />
                    </Box>
                    <List>
                        <ListItem
                            button
                            onClick={() => setActiveContent('users')}
                            selected={activeContent === 'users'}
                        >
                            <ListItemIcon>
                                <PeopleIcon sx={{ color: theme.palette.primary.contrastText }} />
                            </ListItemIcon>
                            <ListItemText primary="Gestion des utilisateurs" />
                        </ListItem>

                        <ListItem
                            button
                            onClick={() => setActiveContent('produits')}
                            selected={activeContent === 'produits'}
                        >
                            <ListItemIcon>
                                <ShoppingBasketIcon sx={{ color: theme.palette.primary.contrastText }} />
                            </ListItemIcon>
                            <ListItemText primary="Gestion des produits" />
                        </ListItem>

                        <ListItem
                            button
                            onClick={() => setActiveContent('routines')}
                            selected={activeContent === 'routines'}
                        >
                            <ListItemIcon>
                                <FitnessCenterIcon sx={{ color: theme.palette.primary.contrastText }} />
                            </ListItemIcon>
                            <ListItemText primary="Gestion des routines" />
                        </ListItem>

                        <ListItem
                            button
                            onClick={() => setActiveContent('messages')}
                            selected={activeContent === 'messages'}
                        >
                            <ListItemIcon>
                                <MessageIcon sx={{ color: theme.palette.primary.contrastText }} />
                            </ListItemIcon>
                            <ListItemText primary="Messagerie" />
                        </ListItem>

                        <ListItem
                            button
                            onClick={() => setActiveContent('annonces')}
                            selected={activeContent === 'annonces'}
                        >
                            <ListItemIcon>
                                <CampaignIcon sx={{ color: theme.palette.primary.contrastText }} />
                            </ListItemIcon>
                            <ListItemText primary="Annonces" />
                        </ListItem>

                        <ListItem
                            button
                            onClick={() => setActiveContent('programmes')}
                            selected={activeContent === 'programmes'}
                        >
                            <ListItemIcon>
                                <EventNoteIcon sx={{ color: theme.palette.primary.contrastText }} />
                            </ListItemIcon>
                            <ListItemText primary="Programmes" />
                        </ListItem>
                        <ListItem button onClick={handleShowStats}>
                            <ListItemIcon>
                                <QueryStatsIcon sx={{ color: theme.palette.primary.contrastText }}/>
                            </ListItemIcon>
                            <ListItemText primary="Statistiques" />
                        </ListItem>
                        <ListItem button onClick={handleShowAppUsage}>
                            <ListItemIcon>
                                <InsightsIcon sx={{ color: theme.palette.primary.contrastText }}/>
                            </ListItemIcon>
                            <ListItemText primary="Apps-usage" />
                        </ListItem>
                    </List>
                </ResizableDrawer>

                {/* Main Content */}
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        width: `calc(100% - ${drawerWidth}px)`,
                        minHeight: '100vh',
                        backgroundColor: theme.palette.background.default
                    }}
                >
                    <ContentComponent />
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default Dashboard;