// src/components/dashboard.js

import React from 'react';
import { TextField, MenuItem } from '@mui/material';

export const USER_ROLES = {
    user: 'user',
    admin: 'admin',
    moderator: 'moderator'
};

const RoleSelector = ({ value, onChange, label = "Rôle", disabled = false }) => {
    return (
        <TextField
            select
            fullWidth
            label={label}
            value={value || 'user'}
            onChange={onChange}
            margin="dense"
            disabled={disabled}
        >
            {Object.entries(USER_ROLES).map(([key]) => (
                <MenuItem key={key} value={key}>
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default RoleSelector;