//apollo-client.js

import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from '@apollo/client/link/context';

// Middleware to handle errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        );
    }

    if (networkError) {
        console.error(`[Network error]: ${networkError}`);
    }
});

// Middleware to add the authentication token to the requests
const authLink = setContext((_, { headers }) => {
    // Retrieve the token from local storage
    const token = localStorage.getItem('token');
    console.log(`Token set in header: ${token}`); // Debug: Log the token value

    // Return the headers including the token
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    };
});



// HttpLink to connect to the GraphQL server
const httpLink = new HttpLink({ uri: "https://api-groomed-hair.site/graphql" });
// const httpLink = new HttpLink({ uri: "http://localhost:4000/graphql" });

// Use `from` to combine the different links
const link = from([
    authLink, // The auth link sets the auth token in the headers
    errorLink, // The error link logs errors
    httpLink, // The HTTP link sends the request to the server
]);

// Creation of the ApolloClient instance
const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: link, // The link chain used by Apollo
});

export default client;
