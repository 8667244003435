// src/constants/roleUtils.js

export const getRoleColor = (role) => {
    switch (role) {
        case 'admin':
            return 'error';
        case 'moderator':
            return 'warning';
        default:
            return 'default';
    }
};

export const getRoleLabel = (role) => {
    return role ? role.charAt(0).toUpperCase() + role.slice(1) : 'User';
};