import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_PRODUCTS, CREATE_PRODUCT, UPDATE_PRODUCT, DELETE_PRODUCT } from '../../graphql/mutations';
import {
    Box, CssBaseline, AppBar, Toolbar, List, Typography, ListItem, ListItemIcon,
    ListItemText, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent,
    TextField, DialogActions, Drawer, Avatar, Menu, MenuItem, TablePagination, Fab
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import '../../styles/Products.css';
import Footer from '../common/Footer';

const ProductDashboard = () => {
    const { loading, error, data, refetch } = useQuery(GET_ALL_PRODUCTS);
    const [createProduct] = useMutation(CREATE_PRODUCT);
    const [updateProduct] = useMutation(UPDATE_PRODUCT);
    const [deleteProduct] = useMutation(DELETE_PRODUCT);

    const [formData, setFormData] = useState({ name: '', price: '', quantite: '', image: '', categorie: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [editingId, setEditingId] = useState('');

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');
    const [openCreateDialog, setOpenCreateDialog] = useState(false);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const resetForm = () => {
        setFormData({ name: '', price: '', quantite: '', image: '', categorie: '' });
        setIsEditing(false);
        setEditingId('');
        setOpenCreateDialog(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, price, quantite, image, categorie } = formData;
        try {
            if (isEditing) {
                await updateProduct({
                    variables: {
                        id: editingId,
                        name,
                        price: parseFloat(price),
                        quantite: parseInt(quantite, 10),
                        image,
                        categorie,
                    },
                });
            } else {
                await createProduct({
                    variables: {
                        name,
                        price: parseFloat(price),
                        quantite: parseInt(quantite, 10),
                        image,
                        categorie,
                    },
                });
            }
            refetch();
            resetForm();
        } catch (error) {
            console.error("Error handling the product:", error);
        }
    };

    const handleUpdate = (product) => {
        setIsEditing(true);
        setEditingId(product.id);
        setFormData({ name: product.name, price: product.price.toString(), quantite: product.quantite.toString(), image: product.image, categorie: product.categorie || '', });
        setOpenCreateDialog(true);
    };

    const handleDelete = async (id) => {
        try {
            await deleteProduct({ variables: { id } });
            refetch();
        } catch (error) {
            console.error("Error deleting the product:", error);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    const filteredProducts = data?.getAllProducts?.filter((product) =>
        product.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.categorie?.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

    const productsToShow = filteredProducts.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);

    return (
        <div>
            <Toolbar />
            <div className='Title'>
                <Box display="flex" justifyContent="space-between" alignItems={'center'}>
                    <h2>Liste des produits</h2>
                    <Fab color="primary" aria-label="add" onClick={() => setOpenCreateDialog(true)} sx={{ mt: -2 }}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>
            {/* <Box display="flex" justifyContent="space-between" alignItems={'center'} sx={{ mb: 4 }}>
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>Liste des produits</Typography>
                <Fab color="primary" aria-label="add" onClick={() => setOpenCreateDialog(true)} sx={{ mt: -2 }}>
                    <AddIcon />
                </Fab>
            </Box> */}
            <Toolbar />
            <div style={{display: "flex", justifyContent: "left", alignItems: 'center', width:'90%', margin:'auto', marginBottom: '20px'}}>
                <TextField
                    label="Rechercher un produit..."
                    variant="outlined"
                    size="small"
                    type='search'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{ width: '100%', maxWidth: '400px', alignItems: 'left' }}
                />
            </div>
            <div className="dashboard-container">
                <TableContainer component={Paper} sx={{ mb: 2 }}>
                    <Table className="products-table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ borderRight: "1px solid white", textAlign: "center" }}>Nom du Produit</TableCell>
                                <TableCell style={{ borderRight: "1px solid white", textAlign: "center" }}>Prix</TableCell>
                                <TableCell style={{ borderRight: "1px solid white", textAlign: "center" }}>Quantité</TableCell>
                                <TableCell style={{ borderRight: "1px solid white", textAlign: "center" }}>Catégorie</TableCell>
                                <TableCell style={{ borderRight: "1px solid white", textAlign: "center" }}>Image</TableCell>
                                <TableCell style={{ borderRight: "1px solid white", textAlign: "center" }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {productsToShow.map((product) => (
                                <TableRow key={product.id}>
                                    <TableCell style={{textAlign: "center"}}>{product.name}</TableCell>
                                    <TableCell style={{textAlign: "center"}}>{product.price} €</TableCell>
                                    <TableCell style={{textAlign: "center"}}>{product.quantite}</TableCell>
                                    <TableCell style={{textAlign: "center"}}>{product.categorie}</TableCell>
                                    <TableCell style={{display: "flex", justifyContent: "center" }}><img src={product.image} alt={product.name} style={{width: "50px", height: "auto"}}/></TableCell>
                                    <TableCell style={{textAlign: "center"}}>
                                        <IconButton aria-label="modifier" color='primary' title="Modifier" onClick={() => handleUpdate(product)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton color='error' aria-label="supprimer" title="Supprimer" onClick={() => handleDelete(product.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Dialog open={openCreateDialog} onClose={resetForm} maxWidth="sm" fullWidth>
                <DialogTitle>{isEditing ? "Modifier le produit" : "Ajouter un nouveau produit"}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nom du produit"
                        type="text"
                        fullWidth
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        label="Prix"
                        type="number"
                        fullWidth
                        name="price"
                        value={formData.price}
                        onChange={handleInputChange}
                    />
                    <TextField
                        margin="dense"
                        label="Quantité"
                        type="number"
                        fullWidth
                        name="quantite"
                        value={formData.quantite}
                        onChange={handleInputChange}
                    />
                    <select name="categorie" value={formData.categorie} onChange={handleInputChange} style={{padding:'16px', borderRadius: '4px', width: '100%', marginTop: '7px'}} required>
                        <option value="">Select Category</option>
                        <option value="bio">Bio</option>
                        <option value="commercialisé">Commercialisé</option>
                    </select>
                    <TextField
                        margin="dense"
                        label="Image URL"
                        type="text"
                        fullWidth
                        name="image"
                        value={formData.image}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button className="form-button" onClick={resetForm}>Annuler</Button>
                    <Button className="form-button" onClick={handleSubmit}>{isEditing ? 'Modifier' : 'Créer'}</Button>
                </DialogActions>
            </Dialog>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 4 }}
            >
                <TablePagination
                    component="div"
                    count={filteredProducts.length}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onPageChange={(event, newPage) => setCurrentPage(newPage)}
                    onRowsPerPageChange={() => {}} // Empêche le changement du nombre de lignes par page
                    rowsPerPageOptions={[5]} // Limite les options à 5 lignes par page
                />
            </Box>
        </div>
    );
};

export default ProductDashboard;