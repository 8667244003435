//discussion.js

import React, {useState, useEffect, useRef, memo} from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams, useLocation } from 'react-router-dom';
import Lottie from 'react-lottie';
import {
    Paper, Box, Typography, CircularProgress, List,
    TextField, Button, Avatar, Alert, Snackbar,
    Fade, IconButton, Tooltip, Menu, MenuItem,
    ListItemIcon, ListItemText, ClickAwayListener,
    alpha, styled, createTheme, ThemeProvider,
    Grid, Badge, keyframes
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import ImageIcon from '@mui/icons-material/Image';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VideocamIcon from '@mui/icons-material/Videocam';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import {CREATE_MESSAGE_MUTATION, GET_MESSAGES_QUERY, MARK_AS_READ_MUTATION, ME_QUERY} from "../../graphql/queries";


// Animation pour les points de suspension
const DotAnimation = keyframes`
  0%, 20% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
  80%, 100% { transform: translateY(0); }
`;
// Theme Configuration
const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3',
            light: '#64b5f6',
            dark: '#1976d2'
        },
        secondary: {
            main: '#f50057',
            light: '#ff4081',
            dark: '#c51162'
        },
        background: {
            default: '#f5f5f5',
            paper: '#ffffff'
        },
        text: {
            primary: '#1c1c1c',
            secondary: '#666666'
        },
        success: {
            main: '#4caf50',
            light: '#81c784',
            dark: '#388e3c'
        }
    },
    typography: {
        fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
        h6: {
            fontWeight: 600,
            fontSize: '1.25rem'
        },
        body1: {
            fontSize: '1rem',
            lineHeight: 1.5
        },
        caption: {
            fontSize: '0.875rem'
        }
    },
    shape: {
        borderRadius: 8
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    borderRadius: 25
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                }
            }
        }
    }
});

// Utility Functions
const groupMessagesByDateAndUser = (messages, currentUser) => {
    if (!messages?.length) return [];

    const groups = [];
    let currentGroup = null;

    const isSameDay = (date1, date2) => {
        if (!isValidTimestamp(date1) || !isValidTimestamp(date2)) return false;
        return new Date(date1).toDateString() === new Date(date2).toDateString();
    };

    messages.forEach((message) => {
        if (!isValidTimestamp(message.timestamp)) {
            console.error('Invalid timestamp for message:', message);
            return;
        }
        const messageDate = new Date(Number(message.timestamp));
        const isSameSender = currentGroup?.sender?.mongoId === message.sender?.mongoId;
        const isWithinTimeframe = currentGroup
            && isSameDay(messageDate, new Date(Number(currentGroup.messages[0].timestamp)))
            && (messageDate - new Date(Number(currentGroup.messages[currentGroup.messages.length - 1].timestamp))) / 1000 / 60 < 5;

        if (currentGroup && isSameSender && isWithinTimeframe) {
            currentGroup.messages.push(message);
        } else {
            if (currentGroup) {
                groups.push(currentGroup);
            }
            currentGroup = {
                id: `group-${message.id}`,
                date: messageDate,
                sender: message.sender,
                messages: [message],
                isCurrentUser: message.sender?.mongoId === currentUser?.mongoId
            };
        }
    });

    if (currentGroup) {
        groups.push(currentGroup);
    }

    return groups;
};


// Styled Components améliorés
const TypingIndicator = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    '& .dot': {
        width: 4,
        height: 4,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
        animation: `${DotAnimation} 1.4s infinite ease-in-out`,
        '&:nth-of-type(2)': {
            animationDelay: '0.2s',
        },
        '&:nth-of-type(3)': {
            animationDelay: '0.4s',
        }
    }
}));

const ScrollToBottomButton = styled(IconButton)(({ theme }) => ({
    position: 'fixed',
    bottom: 90,
    right: 20,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
    zIndex: 1000
}));

const BackButton = styled(IconButton)(({ theme }) => ({
    marginRight: theme.spacing(2),
    color: theme.palette.text.primary,
    transition: 'all 0.3s ease',
    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        transform: 'scale(1.1)',
    }
}));

const isValidTimestamp = (timestamp) => {
    return timestamp && !isNaN(Number(timestamp));
};

const formatDate = (timestamp) => {
    try {
        if (!isValidTimestamp(timestamp)) {
            throw new Error('Invalid timestamp');
        }

        const date = new Date(Number(timestamp));
        const now = new Date();
        const diff = now - date;

        if (diff < 24 * 60 * 60 * 1000) {
            return {
                type: 'today',
                display: "Aujourd'hui"
            };
        }

        if (diff < 48 * 60 * 60 * 1000) {
            return {
                type: 'yesterday',
                display: "Hier"
            };
        }

        if (diff < 7 * 24 * 60 * 60 * 1000) {
            return {
                type: 'thisWeek',
                display: date.toLocaleDateString('fr-FR', { weekday: 'long' })
            };
        }

        return {
            type: 'other',
            display: date.toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
            })
        };
    } catch (error) {
        console.error('Date formatting error:', error);
        return {
            type: 'error',
            display: ''
        };
    }
};


// Liste des emojis fréquemment utilisés
const commonEmojis = [
    '😊', '😂', '👍', '❤️', '😍',
    '🎉', '👏', '🙌', '🤔', '😅',
    '😭', '😎', '🔥', '✨', '👌',
    '💪', '🙏', '💯', '🤓', '😴'
];

// Composant Simple Emoji Picker
const SimpleEmojiPicker = ({ onSelect, onClose }) => (
    <ClickAwayListener onClickAway={onClose}>
        <Paper
            elevation={4}
            sx={{
                p: 2,
                maxWidth: 300,
                maxHeight: 200,
                overflowY: 'auto',
                backgroundColor: 'background.paper'
            }}
        >
            <Grid container spacing={1}>
                {commonEmojis.map((emoji, index) => (
                    <Grid item key={index}>
                        <IconButton
                            onClick={() => onSelect(emoji)}
                            sx={{
                                fontSize: '1.5rem',
                                padding: 1,
                                '&:hover': {
                                    transform: 'scale(1.1)',
                                    transition: 'transform 0.2s'
                                }
                            }}
                        >
                            {emoji}
                        </IconButton>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    </ClickAwayListener>
);

// Styled Components
const MessageContainer = styled(Box)(({ theme, alignRight }) => ({
    display: 'flex',
    justifyContent: alignRight ? 'flex-end' : 'flex-start',
    marginBottom: theme.spacing(2),
    width: '100%',
    transition: 'all 0.3s ease'
}));

const MessageBubble = styled(Box)(({ theme, alignRight, isAdminMessage, isPartOfGroup, isFirst, isLast }) => ({
    backgroundColor: isAdminMessage
        ? alpha(theme.palette.success.light, 0.2)
        : (alignRight ? alpha(theme.palette.primary.main, 0.1) : alpha(theme.palette.grey[100], 0.9)),
    color: isAdminMessage
        ? theme.palette.success.dark
        : (alignRight ? theme.palette.primary.dark : theme.palette.text.primary),
    padding: theme.spacing(2),
    maxWidth: '100%',
    wordWrap: 'break-word',
    marginBottom: isPartOfGroup && !isLast ? theme.spacing(0.5) : 0,
    boxShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.05)}`,
    borderRadius: (() => {
        if (!isPartOfGroup) return '20px';
        if (isFirst) return alignRight ? '20px 20px 5px 20px' : '20px 20px 20px 5px';
        if (isLast) return alignRight ? '5px 5px 20px 20px' : '20px 5px 5px 20px';
        return '5px';
    })()
}));


const StyledAvatar = styled(Avatar)(({ theme }) => ({
    border: `2px solid ${theme.palette.background.paper}`,
    boxShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.1)}`,
    transition: 'transform 0.2s ease',
    '&:hover': {
        transform: 'scale(1.1)',
    }
}));

const MessageStatus = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
}));

const DateSeparator = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3, 0),
    '&::before, &::after': {
        content: '""',
        flex: 1,
        borderBottom: `1px solid ${alpha(theme.palette.divider, 0.2)}`
    }
}));

const DateBadge = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(0, 2),
    padding: theme.spacing(0.5, 2),
    backgroundColor: alpha(theme.palette.background.paper, 0.9),
    borderRadius: 16,
    fontSize: '0.85rem',
    color: theme.palette.text.secondary,
    boxShadow: `0 2px 4px ${alpha(theme.palette.common.black, 0.05)}`
}));

// Message Components avec améliorations
const MessageGroup = memo(({ group, isLastGroup }) => {
    return (
        <MessageContainer alignRight={group.isCurrentUser}>
            {!group.isCurrentUser && (
                <StyledAvatar
                    sx={{ mr: 1, alignSelf: 'flex-start' }}
                    alt={`${group.sender?.firstName} ${group.sender?.lastName}`}
                />
            )}
            <Box sx={{ maxWidth: '70%' }}>
                {group.messages.map((message, index) => (
                    <MessageBubble
                        key={message.id}
                        alignRight={group.isCurrentUser}
                        isAdminMessage={group.sender?.role === 'admin'}
                        isPartOfGroup={group.messages.length > 1}
                        isFirst={index === 0}
                        isLast={index === group.messages.length - 1}
                    >
                        {index === 0 && (
                            <Typography
                                variant="caption"
                                sx={{
                                    display: 'block',
                                    mb: 0.5,
                                    color: alpha(theme.palette.text.primary, 0.7),
                                    fontWeight: 500
                                }}
                            >
                                {message.sender?.firstName} {message.sender?.lastName}
                            </Typography>
                        )}
                        <Typography variant="body1">
                            {message.content}
                        </Typography>
                        {/* Affichage du fichier joint */}
                        {message.fileUrl && (
                            <Box sx={{ mt: 1, maxWidth: '200px' }}>
                                {message.fileType?.startsWith('image/') ? (
                                    <img
                                        src={message.fileUrl}
                                        alt="attachment"
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            borderRadius: 8,
                                            objectFit: 'cover'
                                        }}
                                    />
                                ) : message.fileType?.startsWith('video/') ? (
                                    <video
                                        src={message.fileUrl}
                                        controls
                                        style={{
                                            width: '100%',
                                            borderRadius: 8
                                        }}
                                    />
                                ) : (
                                    <Button
                                        startIcon={<InsertDriveFileIcon />}
                                        variant="outlined"
                                        size="small"
                                        href={message.fileUrl}
                                        target="_blank"
                                        sx={{ mt: 1 }}
                                    >
                                        Télécharger le fichier
                                    </Button>
                                )}
                            </Box>
                        )}
                        {index === group.messages.length - 1 && (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                mt: 0.5,
                                gap: 1
                            }}>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: alpha(theme.palette.text.secondary, 0.8)
                                    }}
                                >
                                    {message.timestamp && isValidTimestamp(message.timestamp)
                                        ? new Date(Number(message.timestamp)).toLocaleTimeString('fr-FR', {
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })
                                        : ''
                                    }
                                </Typography>
                                {group.isCurrentUser && (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {message.isRead ? (
                                            <DoneAllIcon
                                                sx={{
                                                    fontSize: 16,
                                                    color: theme.palette.primary.main
                                                }}
                                            />
                                        ) : (
                                            <DoneIcon
                                                sx={{
                                                    fontSize: 16,
                                                    color: alpha(theme.palette.text.secondary, 0.6)
                                                }}
                                            />
                                        )}
                                    </Box>
                                )}
                            </Box>
                        )}
                    </MessageBubble>
                ))}
            </Box>
            {group.isCurrentUser && !isLastGroup && (
                <StyledAvatar
                    sx={{ ml: 1, alignSelf: 'flex-start' }}
                    alt={`${group.sender?.firstName} ${group.sender?.lastName}`}
                />
            )}
        </MessageContainer>
    );
});

MessageGroup.displayName = 'MessageGroup';



const InputContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
    backgroundColor: theme.palette.background.paper,
    backdropFilter: 'blur(10px)',
    display: 'flex',
    gap: theme.spacing(1),
    position: 'relative',
    zIndex: 2
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: 25,
        backgroundColor: alpha(theme.palette.background.default, 0.6),
        transition: 'all 0.3s ease',
        '&:hover': {
            backgroundColor: alpha(theme.palette.background.default, 0.8),
        },
        '&.Mui-focused': {
            backgroundColor: alpha(theme.palette.background.default, 0.9),
            boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
        }
    }
}));


const SendButton = styled(Button)(({ theme }) => ({
    borderRadius: 50,
    padding: theme.spacing(1.5),
    minWidth: 'unset',
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.3s ease',
    '&:hover': {
        transform: 'scale(1.05)',
    },
    '&.Mui-disabled': {
        backgroundColor: alpha(theme.palette.action.disabled, 0.1),
    }
}));

// PreviewAttachment Component
const PreviewAttachment = ({ file, onRemove }) => {
    if (!file) return null;

    const isImage = file.type.startsWith('image/');
    const isVideo = file.type.startsWith('video/');

    return (
        <Paper
            elevation={2}
            sx={{
                mt: 2,
                mb: 2,
                position: 'relative',
                p: 2,
                backgroundColor: theme.palette.background.paper,
                maxWidth: '300px', // Taille maximale fixe
                width: '100%'
            }}
        >
            <IconButton
                size="small"
                onClick={onRemove}
                sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    bgcolor: 'background.paper',
                    '&:hover': {
                        bgcolor: 'action.hover'
                    }
                }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                maxHeight: '100px', // Hauteur maximale fixe
                overflow: 'hidden'
            }}>
                {isImage ? (
                    <Box sx={{ position: 'relative', width: 80, height: 80, flexShrink: 0 }}>
                        <img
                            src={URL.createObjectURL(file)}
                            alt="Preview"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: theme.shape.borderRadius
                            }}
                        />
                    </Box>
                ) : isVideo ? (
                    <Box sx={{ position: 'relative', width: 80, height: 80, flexShrink: 0 }}>
                        <video
                            src={URL.createObjectURL(file)}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: theme.shape.borderRadius
                            }}
                        />
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <InsertDriveFileIcon sx={{ fontSize: 40, color: 'primary.main', flexShrink: 0 }} />
                        <Box sx={{ minWidth: 0 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 500, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                {file.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {(file.size / 1024 / 1024).toFixed(2)} MB
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
        </Paper>
    );
};



// Main Discussion Component
const Discussion = ({ conversation }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const messagesEndRef = useRef(null);
    const audioRef = useRef(new Audio('/sounds/message.mp3'));
    const [selectedFile, setSelectedFile] = useState(false);
    const [newMessage, setNewMessage] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [attachmentMenuAnchor, setAttachmentMenuAnchor] = useState(null);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const { mongoId: conversationId, participants } = conversation;

    const [hasNewMessages, setHasNewMessages] = useState(false);

    const listRef = useRef(null);


    // Avec les autres handlers
    const handleScroll = () => {
        const list = listRef.current;
        if (!list) return;

        const { scrollTop, scrollHeight, clientHeight } = list;
        const isBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
        setHasNewMessages(!isBottom);
    };

    //const conversationId = location.state?.conversationId;
    const userParticipant = conversation.participants.find(
        (participant) => participant.role === "user"
    );

    const userName = userParticipant
        ? `${userParticipant.firstName} ${userParticipant.lastName}`
        : "Utilisateur";

    // GraphQL queries and mutations
    const { data: userData, loading: userLoading } = useQuery(ME_QUERY);
    const currentUser = userData?.me?.user;

    const { loading, data, error, refetch } = useQuery(GET_MESSAGES_QUERY, {
        variables: {
            conversationId: conversationId?.toString()
        },
        skip: !conversationId,
        pollInterval: 5000,
        onError: (error) => {
            console.error("GraphQL error details:", {
                message: error.message,
                graphQLErrors: error.graphQLErrors,
                networkError: error.networkError,
                conversationId
            });
        }
    });

    const messages = data?.messages || [];

    useEffect(() => {
        console.log("Discussion mounted with:", {
            conversationId,
            locationState: location.state
        });
    }, [conversationId, location.state]);

    useEffect(() => {
        const list = listRef.current;
        if (!list || messages.length === 0) return;

        const { scrollTop, scrollHeight, clientHeight } = list;
        const isBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 1;
        setHasNewMessages(!isBottom);
    }, [messages]);


    const [markAsRead] = useMutation(MARK_AS_READ_MUTATION, {
        onError: (error) => {
            console.error('Error marking messages as read:', error);
        }
    });

    const [createMessage] = useMutation(CREATE_MESSAGE_MUTATION, {
        onCompleted: () => {
            playMessageSound();
            refetch();
            setNewMessage('');
            setSnackbar({
                open: true,
                message: 'Message envoyé avec succès',
                severity: 'success'
            });
            scrollToBottom();
        },
        onError: (error) => {
            setSnackbar({
                open: true,
                message: `Erreur: ${error.message}`,
                severity: 'error'
            });
        }
    });

    const playMessageSound = () => {
        const audio = audioRef.current;
        audio.currentTime = 0;
        audio.play().catch(error => console.log('Error playing sound:', error));
    };

    // Pour s'assurer que le défilement fonctionne correctement
    const scrollToBottom = () => {
        const list = listRef.current;
        const messagesEnd = messagesEndRef.current;
        if (!list || !messagesEnd) return;

        try {
            messagesEnd.scrollIntoView({ behavior: 'smooth' });
            setHasNewMessages(false);
        } catch (error) {
            console.error('Error scrolling to bottom:', error);
        }
    };

    useEffect(() => {
        if (conversationId && messages.length > 0) {
            const unreadMessages = messages.filter(
                msg => !msg.read && msg.sender?.mongoId !== currentUser?.mongoId
            );

            if (unreadMessages.length > 0) {
                markAsRead({
                    variables: { conversationId }
                }).catch(error => {
                    console.error('Failed to mark messages as read:', error);
                });
            }
        }
    }, [messages, conversationId, currentUser?.mongoId, markAsRead]);

    useEffect(() => {
        const audio = audioRef.current;
        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, []);


    const handleSendMessage = async () => {
        if (!newMessage.trim() && !selectedFile) return;

        try {
            let fileUrl = null;

            // Si un fichier est sélectionné, on l'upload d'abord
            if (selectedFile) {
                // Créer un FormData pour l'upload du fichier
                const formData = new FormData();
                formData.append('file', selectedFile);

                // Uploader le fichier vers votre serveur
                try {
                    const uploadResponse = await fetch('VOTRE_URL_UPLOAD', {
                        method: 'POST',
                        body: formData,
                    });

                    if (!uploadResponse.ok) {
                        throw new Error('Upload failed');
                    }

                    const uploadResult = await uploadResponse.json();
                    fileUrl = uploadResult.fileUrl; // L'URL retournée par votre serveur
                } catch (error) {
                    console.error('File upload failed:', error);
                    setSnackbar({
                        open: true,
                        message: 'Erreur lors de l\'upload du fichier',
                        severity: 'error'
                    });
                    return;
                }
            }

            // Envoyer le message avec l'URL du fichier si disponible
            await createMessage({
                variables: {
                    conversationId,
                    content: newMessage.trim(),
                    fileUrl: fileUrl,
                    fileType: selectedFile ? selectedFile.type : null
                }
            });

            // Réinitialiser après l'envoi
            setSelectedFile(null);
            setNewMessage('');

        } catch (error) {
            console.error('Failed to send message:', error);
            setSnackbar({
                open: true,
                message: 'Erreur lors de l\'envoi du message',
                severity: 'error'
            });
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const handleEmojiSelect = (emoji) => {
        setNewMessage(prev => prev + emoji);
        setShowEmojiPicker(false);
    };

    const handleAttachment = (type) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = type === 'image' ? 'image/*' :
            type === 'video' ? 'video/*' :
                '*/*';

        input.onchange = (e) => {
            const file = e.target.files[0];
            if (file) {
                setSelectedFile(file);
            }
        };

        input.click();
        setAttachmentMenuAnchor(null);
    };


    // Loading state
    if (loading || userLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress />
            </Box>
        );
    }

    // Modification du rendu des messages
    const messageGroups = groupMessagesByDateAndUser(messages, currentUser);
    let lastDate = null;


    return (
        <ThemeProvider theme={theme}>
            <Paper
                elevation={3}
                sx={{
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: theme.palette.background.default,
                    overflow: 'hidden'
                }}
            >
                {/* Header */}
                <Box sx={{
                    p: 2,
                    borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                    backgroundColor: alpha(theme.palette.background.paper, 0.8),
                    backdropFilter: 'blur(10px)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    zIndex: 2
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
                        <BackButton
                            onClick={() => navigate(-1)}
                            aria-label="retour"
                        >
                            <ArrowBackIcon />
                        </BackButton>

                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <StyledAvatar
                                alt={userName}
                                sx={{
                                    width: 48,
                                    height: 48,
                                    border: `2px solid ${theme.palette.primary.main}`,
                                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                    transition: 'transform 0.3s ease',
                                    '&:hover': {
                                        transform: 'scale(1.1)',
                                    }
                                }}
                            />
                            <Box>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: 600,
                                        color: theme.palette.text.primary
                                    }}
                                >
                                    {userName}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: theme.palette.text.secondary,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 0.5
                                    }}
                                >
                                    {isTyping ? (
                                        <>
                                            En train d'écrire...
                                            <span style={{ display: 'inline-flex', gap: '2px' }}>
                                <span className="dot" />
                                <span className="dot" />
                                <span className="dot" />
                            </span>
                                        </>
                                    ) : (
                                        'En ligne'
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <List
                    ref={listRef}
                    onScroll={handleScroll}
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        '&::-webkit-scrollbar': {
                            width: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: 'transparent',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: alpha(theme.palette.primary.main, 0.2),
                            borderRadius: '4px',
                            '&:hover': {
                                background: alpha(theme.palette.primary.main, 0.3),
                            }
                        }
                    }}
                >
                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        {messages.length === 0 ? (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <Typography variant="h6" color="textSecondary">
                                    Commencez la conversation
                                </Typography>
                            </Box>
                        ) : (
                            <>
                                {messageGroups.map((group, index) => {
                                    const currentDate = formatDate(group.date);
                                    const showDateSeparator = currentDate.display !== lastDate?.display;
                                    lastDate = currentDate;

                                    return (
                                        <Box key={group.id}>
                                            {showDateSeparator && (
                                                <DateSeparator>
                                                    <DateBadge>
                                                        {currentDate.display}
                                                    </DateBadge>
                                                </DateSeparator>
                                            )}
                                            <Fade in timeout={500}>
                                                <Box>
                                                    <MessageGroup
                                                        group={group}
                                                        isLastGroup={index === messageGroups.length - 1}
                                                    />
                                                </Box>
                                            </Fade>
                                        </Box>
                                    );
                                })}
                                <div ref={messagesEndRef} />
                            </>
                        )}
                    </Box>
                </List>

                {/* Input Area */}
                <InputContainer>
                    {selectedFile && (
                        <PreviewAttachment
                            file={selectedFile}
                            onRemove={() => setSelectedFile(null)}
                        />
                    )}
                    <Tooltip title="Joindre un fichier">
                        <IconButton
                            color="primary"
                            onClick={(e) => setAttachmentMenuAnchor(e.currentTarget)}
                            sx={{
                                borderRadius: '50%',
                                padding: 1.5,
                                backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                '&:hover': {
                                    backgroundColor: alpha(theme.palette.primary.main, 0.2),
                                }
                            }}
                        >
                            <AttachFileIcon />
                        </IconButton>
                    </Tooltip>


                    <StyledTextField
                        fullWidth
                        multiline
                        maxRows={4}
                        value={newMessage}
                        onChange={(e) => {
                            setNewMessage(e.target.value);
                            if (!isTyping) {
                                setIsTyping(true);
                                setTimeout(() => setIsTyping(false), 2000);
                            }
                        }}
                        onKeyPress={handleKeyPress}
                        placeholder="Tapez votre message..."
                        variant="outlined"
                        size="medium"
                        InputProps={{
                            endAdornment: (
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Tooltip title="Emoji">
                                        <IconButton
                                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                            size="small"
                                        >
                                            <EmojiEmotionsIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Message vocal">
                                        <IconButton size="small">
                                            <KeyboardVoiceIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            ),
                        }}
                    />

                    <SendButton
                        variant="contained"
                        onClick={handleSendMessage}
                        disabled={!newMessage.trim() && !selectedFile}
                    >
                        <SendIcon />
                    </SendButton>
                </InputContainer>

                {/* Emoji Picker */}
                {showEmojiPicker && (
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: '80px',
                            right: '20px',
                            zIndex: 1000
                        }}
                    >
                        <SimpleEmojiPicker
                            onSelect={handleEmojiSelect}
                            onClose={() => setShowEmojiPicker(false)}
                        />
                    </Box>
                )}

                {hasNewMessages && (
                    <ScrollToBottomButton onClick={scrollToBottom}>
                        <KeyboardArrowDownIcon />
                    </ScrollToBottomButton>
                )}

                {/* Attachment Menu */}
                <Menu
                    anchorEl={attachmentMenuAnchor}
                    open={Boolean(attachmentMenuAnchor)}
                    onClose={() => setAttachmentMenuAnchor(null)}
                    PaperProps={{
                        elevation: 3,
                        sx: {
                            mt: 1,
                            minWidth: 180,
                        }
                    }}
                >
                    <MenuItem onClick={() => handleAttachment('image')}>
                        <ListItemIcon>
                            <ImageIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary="Image" />
                    </MenuItem>
                    <MenuItem onClick={() => handleAttachment('video')}>
                        <ListItemIcon>
                            <VideocamIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary="Vidéo" />
                    </MenuItem>
                    <MenuItem onClick={() => handleAttachment('file')}>
                        <ListItemIcon>
                            <InsertDriveFileIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary="Document" />
                    </MenuItem>
                </Menu>

                {/* Snackbar for notifications */}
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={() => setSnackbar({ ...snackbar, open: false })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert
                        onClose={() => setSnackbar({ ...snackbar, open: false })}
                        severity={snackbar.severity}
                        variant="filled"
                        sx={{
                            borderRadius: 2,
                            boxShadow: 3
                        }}
                    >
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            </Paper>
        </ThemeProvider>
    );
};

export default Discussion;