//mutation.js

import { gql } from '@apollo/client';

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($firstName: String!, $lastName: String!, $email: String!, $mdp: String!, $typeDeCheveux: String!, $age: Int!, $role: UserRole) {
    createUser(firstName: $firstName, lastName: $lastName, email: $email, mdp: $mdp, typeDeCheveux: $typeDeCheveux, age: $age, role: $role) {
      id
      firstName
      lastName
      email
      profileImage    
      typeDeCheveux
      age
      role
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    getAllUsers {
      id
      firstName
      lastName
      email
      profileImage
      typeDeCheveux
      age
      role
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $id: ID!,
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $profileImage: String,
    $typeDeCheveux: String,
    $role: UserRole,
    $age: Int!
  ) {
    updateUser(
      id: $id,
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      profileImage: $profileImage,
      typeDeCheveux: $typeDeCheveux,
      role: $role,
      age: $age
    ) {
      id
      firstName
      lastName
      email
      profileImage
      typeDeCheveux
      age
      role
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $mdp: String!) {
    login(email: $email, mdp: $mdp) {
      user {
        id
        mongoId
        firstName
        lastName
        age
        email
        profileImage
        mdp
        typeDeCheveux
        role
      }
      token
    }
  }
`;

export const GET_ALL_PRODUCTS = gql`
  query GetAllProducts {
    getAllProducts {
      id
      name
      price
      quantite
      image
      categorie
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation CreateProduit($name: String!, $price: Float!, $quantite: Int!, $image: String!, $categorie: String) {
    createProduit(name: $name, price: $price, quantite: $quantite, image: $image, categorie: $categorie) {
      id
      name
      price
      quantite
      image
      categorie
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduit($id: ID!, $name: String, $price: Float, $quantite: Int, $image: String, $categorie: String) {
    updateProduit(id: $id, name: $name, price: $price, quantite: $quantite, image: $image, categorie: $categorie) {
      id
      name
      price
      quantite
      image
      categorie
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduit($id: ID!) {
    deleteProduit(id: $id)
  }
`;


export const GET_ALL_ROUTINES = gql`
  query GetAllRoutines {
    getAllRoutines {
      id
      name
      instructions
      startTime
      endTime
      duration
      type_hair
      minAge
      maxAge
      createdAt
      produits {
        id
        name
        price
        quantite
        image
      }
    }
  }
`;

export const CREATE_ROUTINE = gql`
  mutation CreateRoutine($name: String!, $instructions: String!, $startTime: String!, $endTime: String!, $duration: Int!, $type_hair: String, $produits: [ID!]!, $minAge: Int!, $maxAge: Int!) {
    createRoutine(name: $name, instructions: $instructions, startTime: $startTime, endTime: $endTime, duration: $duration, type_hair: $type_hair, produits: $produits, minAge: $minAge, maxAge: $maxAge) {
      id
      name
      instructions
      startTime
      endTime
      duration
      type_hair
      minAge
      maxAge
      produits {
        id
        name
        price
        quantite
        image
      }
    }
  }
`;

export const UPDATE_ROUTINE = gql`
  mutation UpdateRoutine(
    $id: ID!,
    $name: String,
    $instructions: String,
    $startTime: String,
    $endTime: String,
    $duration: Int,
    $produits: [ID!],
    $type_hair: String,
    $minAge: Int!,
    $maxAge: Int!
  ) {
    updateRoutine(
      id: $id,
      name: $name,
      instructions: $instructions,
      startTime: $startTime,
      endTime: $endTime,
      duration: $duration,
      produits: $produits,
      type_hair: $type_hair,
      minAge: $minAge,
      maxAge: $maxAge
    ) {
      id
      name
      instructions
      startTime
      endTime
      duration
      type_hair
      minAge
      maxAge
      produits {
        id
        name
        price
        quantite
        image
      }
    }
  }
`;


export const DELETE_ROUTINE = gql`
  mutation DeleteRoutine($id: ID!) {
    deleteRoutine(id: $id)
  }
`;


// Mutation pour créer un programme
export const CREATE_PROGRAMME = gql`
  mutation CreateProgramm($name: String!, $description: String!, $challengeDuMoment: String!, $routineIds: [ID!]) {
    createProgramm(
      name: $name,
      description: $description,
      challengeDuMoment: $challengeDuMoment,
      routineIds: $routineIds
    ) {
      id
      name
      description
      challengeDuMoment
      routines {
        id
        name
        type_hair
        instructions
        duration
      }
    }
  }
`;


export const UPDATE_PROGRAMME = gql`
  mutation UpdateProgramm($id: ID!, $name: String!, $description: String!, $challengeDuMoment: String!, $routineIds: [ID!]) {
    updateProgramm(
      id: $id,
      name: $name,
      description: $description,
      challengeDuMoment: $challengeDuMoment,
      routineIds: $routineIds 
    ) {
      id
      name
      description
      challengeDuMoment
      routines {
        id
        name
        type_hair
        instructions
        duration
      }
    }
  }
`;

export const DELETE_PROGRAMME = gql`
  mutation DeleteProgramm($id: ID!) {
    deleteProgramm(id: $id)
  }
`;


export const CREATE_ANNONCE = gql`
  mutation CreateAnnonce($title: String!, $media: String!, $description: String!) {
    createAnnonce(title: $title, media: $media, description: $description) {
      id
      title
      media
      description
    }
  }
`;

export const DELETE_ANNONCE = gql`
  mutation DeleteAnnonce($id: ID!) {
    deleteAnnonce(id: $id)
  }
`;

export const UPDATE_ANNONCE = gql`
  mutation UpdateAnnonce($id: ID!, $title: String!, $media: String!, $description: String!) {
    updateAnnonce(id: $id, title: $title, media: $media, description: $description) {
      id
      title
      media
      description
    }
  }
`;


