import React, { useState } from 'react';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Box,
    Toolbar,
    Divider,
    IconButton
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DragHandleIcon from '@mui/icons-material/DragHandle';

const ResizableDrawer = ({
                             children,
                             drawerWidth,
                             setDrawerWidth,
                             isOpen,
                             handleDrawerClose,
                             theme
                         }) => {
    const [isResizing, setIsResizing] = useState(false);
    const minDrawerWidth = 180;
    const maxDrawerWidth = 400;

    const handleMouseDown = (e) => {
        e.preventDefault();
        setIsResizing(true);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        if (isResizing) {
            const newWidth = e.clientX;
            if (newWidth >= minDrawerWidth && newWidth <= maxDrawerWidth) {
                setDrawerWidth(newWidth);
            }
        }
    };

    const handleMouseUp = () => {
        setIsResizing(false);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    return (
        <Drawer
            variant="permanent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                position: 'relative',
                [`& .MuiDrawer-paper`]: {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    transition: isResizing ? 'none' : 'width 0.2s ease',
                    overflowX: 'hidden',
                    backgroundColor: theme?.palette?.primary?.main || '#6A0D47',
                    color: theme?.palette?.primary?.contrastText || '#ecfad8',
                },
            }}
            open={isOpen}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Toolbar />

                {/* Content */}
                <Box sx={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}>
                    {children}
                </Box>

                {/* Resize Handle */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        width: '4px',
                        height: '100%',
                        cursor: 'col-resize',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        },
                        '&:active': {
                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                        }
                    }}
                    onMouseDown={handleMouseDown}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: '2px',
                            transform: 'translateY(-50%)',
                            visibility: isResizing ? 'visible' : 'hidden',
                            '&:hover': { visibility: 'visible' },
                            color: 'rgba(255, 255, 255, 0.7)'
                        }}
                    >
                        <DragHandleIcon fontSize="small" />
                    </Box>
                </Box>
            </Box>
        </Drawer>
    );
};

export default ResizableDrawer;