import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_USERS } from '../../graphql/mutations';
import { GET_ALL_PRODUCTS } from "../../graphql/mutations";
import { Chart } from 'react-google-charts';
import '../../styles/Statistique.css';
import { Toolbar, Box} from '@mui/material';

const Statistique = () => {
    const { loading, error, data } = useQuery(GET_ALL_USERS);
    const { loading: loading1, error: error1, data: data1 } = useQuery(GET_ALL_PRODUCTS);

    const [hairTypeCounts, setHairTypeCounts] = useState({});
    const [productCounts, setProductCounts] = useState({});

    useEffect(() => {
        if (!loading && !error) {
            const counts = {};
            data.getAllUsers.forEach(user => {
                const hairType = user.typeDeCheveux;
                counts[hairType] = counts[hairType] ? counts[hairType] + 1 : 1;
            });
            setHairTypeCounts(counts);
        }
    }, [data, loading, error]);

    useEffect(() => {
        if (!loading1 && !error1) {
            const counts = {};
            data1.getAllProducts.forEach(product => {
                const category = product.categorie;
                counts[category] = counts[category] ? counts[category] + 1 : 1;
            });
            setProductCounts(counts);
        }
    }, [data1, loading1, error1]);

    const getCategoryColor = (category) => {
        switch (category) {
            case 'bio':
                return '#dd4477'; // Vert
            case 'commercialisé':
                return 'green'; // Rouge
            default:
                return 'blue'; // Bleu
        }
    };


    return (
        <div className="">
            <Toolbar />
            <div className='Title'>
                <Box display="flex" justifyContent="space-between" alignItems={'center'}>
                    <h2>Nos Statistiques</h2>
                </Box>
            </div>
            <div className="statistique-container">
                {loading && <p>Chargement...</p>}
                {error && <p>Erreur de chargement des données</p>}
                {Object.keys(hairTypeCounts).length > 0 && (
                    <>
                        <Chart
                            width={'100%'}
                            height={'400px'}
                            chartType="PieChart"
                            loader={<div>Chargement du graphique...</div>}
                            data={[['Type de cheveux', 'Nombre']].concat(
                                Object.entries(hairTypeCounts)
                            )}
                            options={{
                                title: 'Répartition des types de cheveux',
                                is3D: true,
                            }}
                        />
                    </>
                )}
                {loading1 && <p>Chargement...</p>}
                {error1 && <p>Erreur de chargement des données</p>}
                {Object.keys(productCounts).length > 0 && (
                    <Chart
                        width="100%"
                        height="400px"
                        chartType="ColumnChart"
                        loader={<div>Chargement du graphique...</div>}
                        data={[['Catégorie', 'Nombre de Produits']].concat(
                            Object.entries(productCounts).map(([category, count]) => [category, count])
                        )}
                        options={{
                            title: 'Popularité des Produits par Catégorie',
                            chartArea: { width: "70%" },
                            colors: Object.keys(productCounts).map(product => getCategoryColor(product)),
                            legend: { position: 'bottom' },
                        }}
                    />
                )}

            </div>
            <Toolbar />
        </div>
    );
};

export default Statistique;
