import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import {
    Box,
    TextField,
    Typography,
    Alert,
    Snackbar,
    CircularProgress,
    Grid,
    DialogTitle,
    DialogContent,
    DialogActions,
    Select,
    MenuItem,
    InputLabel,
    OutlinedInput,
    Divider,
    Button,
    Dialog,
    Chip,
    Paper,
    Fab,
    Container,
    AppBar,
    Toolbar,
    FormControl,
    useTheme,
    alpha,
} from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import SearchIcon from '@mui/icons-material/Search';

import { StyledContainer, EnhancedProgrammeCard, ProgrammeTitle,
    ProgrammeDescription, ProgrammeSection, RoutineChips, ActionButton,
    DetailChip, StyledTextField, FilterFormControl, SearchContainer, RoutineCard } from '../../styles/ProgrammeStyle';

import { GET_ALL_PROGRAMMES } from '../../graphql/queries';
import { HAIR_TYPES, getHairTypeInfo, getCategoryColor } from '../../constants/hairTypes';
import HairTypeSelector from '../../../src/components/HairTypeSelector';
import {CREATE_PROGRAMME, DELETE_PROGRAMME, GET_ALL_ROUTINES, UPDATE_PROGRAMME} from "../../graphql/mutations";


const Programmes = () => {
    const theme = useTheme();
    const [modalOpen, setModalOpen] = useState(false);

    const isInAgeRange = (minAge, maxAge, range) => {
        const [rangeMin, rangeMax] = range.split('-').map(Number);
        return minAge >= rangeMin && maxAge <= rangeMax;
    };

    const [formData, setFormData] = useState({
        id: null,
        name: '',
        description: '',
        challengeDuMoment: '',
        routineIds: [],
        hairType: '',
        ageRange: ''
    });


    const [showPreview, setShowPreview] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isEditing, setIsEditing] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [programmeToDelete, setProgrammeToDelete] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filters, setFilters] = useState({
        hairType: '',
        ageRange: '',
    });

    const { data: programmesData, loading: programmesLoading, refetch } = useQuery(GET_ALL_PROGRAMMES);
    const { data: routinesData, loading: routinesLoading } = useQuery(GET_ALL_ROUTINES);
    const [createProgramme, { loading: createLoading }] = useMutation(CREATE_PROGRAMME);
    const [updateProgramme, { loading: updateLoading }] = useMutation(UPDATE_PROGRAMME);
    const [deleteProgramme, { loading: deleteLoading }] = useMutation(DELETE_PROGRAMME);

    // Functions
    const handleSuccess = (message) => {
        setSnackbarMessage(message);
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        resetForm();
        refetch();
        setModalOpen(false);
    };

    const handleError = (error) => {
        setSnackbarMessage(`Erreur : ${error.message}`);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
    };

    const resetForm = () => {
        setFormData({
            id: null,
            name: '',
            description: '',
            challengeDuMoment: '',
            routineIds: [],
            hairType: '',
            ageRange: ''
        });
        setIsEditing(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Validation des champs requis
            if (!formData.name || !formData.description || !formData.challengeDuMoment) {
                setSnackbarMessage('Veuillez remplir tous les champs obligatoires');
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
                return;
            }

            const mutationVariables = {
                name: formData.name,
                description: formData.description,
                challengeDuMoment: formData.challengeDuMoment,
                routineIds: formData.routineIds,
                hairType: formData.hairType,
                ageRange: formData.ageRange
            };

            console.log('Envoi des données:', mutationVariables);

            if (isEditing) {
                await updateProgramme({
                    variables: {
                        id: formData.id,
                        ...mutationVariables,
                    },
                });
                handleSuccess('Programme mis à jour avec succès !');
            } else {
                const { data } = await createProgramme({
                    variables: mutationVariables,
                    refetchQueries: [{ query: GET_ALL_PROGRAMMES }],
                    update: (cache, { data: { createProgramme } }) => {
                        try {
                            const existingProgrammes = cache.readQuery({
                                query: GET_ALL_PROGRAMMES
                            });

                            if (existingProgrammes?.getAllProgramm) {
                                cache.writeQuery({
                                    query: GET_ALL_PROGRAMMES,
                                    data: {
                                        getAllProgramm: [
                                            ...existingProgrammes.getAllProgramm,
                                            createProgramme
                                        ]
                                    }
                                });
                            }
                        } catch (error) {
                            console.error('Erreur lors de la mise à jour du cache:', error);
                        }
                    }
                });
                console.log('Programme créé:', data);
                handleSuccess('Programme créé avec succès !');
            }
        } catch (error) {
            console.error('Erreur lors de la création/mise à jour:', error);
            handleError(error);
        }
    };


    const handleEdit = (programme) => {
        setFormData({
            id: programme.id,
            name: programme.name,
            description: programme.description,
            challengeDuMoment: programme.challengeDuMoment,
            routineIds: programme.routines?.map(r => r.id) || [],
            hairType: programme.hairType || '',
            ageRange: programme.ageRange || ''
        });
        setIsEditing(true);
        setModalOpen(true);
    };

    const handleDeleteClick = (programme) => {
        setProgrammeToDelete(programme);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteProgramme({
                variables: { id: programmeToDelete.id },
            });
            handleSuccess('Programme supprimé avec succès');
            setDeleteDialogOpen(false);
            setProgrammeToDelete(null);
        } catch (error) {
            handleError(error);
        }
    };

    const getFilteredProgrammes = () => {
        if (!programmesData?.getAllProgramm) return [];

        return programmesData.getAllProgramm.filter(programme =>
            programme.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            programme.description.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    const renderFormContent = () => (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <StyledTextField
                name="name"
                label="Nom du programme"
                variant="outlined"
                fullWidth
                required
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />

            <StyledTextField
                name="description"
                label="Description"
                variant="outlined"
                fullWidth
                required
                multiline
                rows={4}
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            />

            <StyledTextField
                name="challengeDuMoment"
                label="Challenge du moment"
                variant="outlined"
                fullWidth
                required
                multiline
                rows={3}
                value={formData.challengeDuMoment}
                onChange={(e) => setFormData({ ...formData, challengeDuMoment: e.target.value })}
            />

            <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={6}>
                    <FilterFormControl fullWidth>
                        <InputLabel id="hair-type-label">Type de cheveux</InputLabel>
                        <Select
                            labelId="hair-type-label"
                            value={formData.hairType || ''}
                            onChange={(e) => setFormData({ ...formData, hairType: e.target.value })}
                            input={<OutlinedInput label="Type de cheveux" />}
                        >
                            <MenuItem value="">
                                <em>Sélectionner un type</em>
                            </MenuItem>
                            {HAIR_TYPES.map((type) => (
                                <MenuItem
                                    key={type.id}
                                    value={type.id}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1
                                    }}
                                >
                                    <ContentCutIcon fontSize="small" />
                                    {type.name}
                                    {type.category && (
                                        <Chip
                                            size="small"
                                            label={type.category}
                                            color={getCategoryColor(type.category)}
                                            sx={{ ml: 1 }}
                                        />
                                    )}
                                </MenuItem>
                            ))}
                        </Select>
                    </FilterFormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FilterFormControl fullWidth>
                        <InputLabel>Tranche d'âge</InputLabel>
                        <Select
                            value={formData.ageRange || ''}
                            onChange={(e) => setFormData({ ...formData, ageRange: e.target.value })}
                            input={<OutlinedInput label="Tranche d'âge" />}
                        >
                            <MenuItem value="">
                                <em>Sélectionner une tranche</em>
                            </MenuItem>
                            <MenuItem value="0-12">0-12 ans</MenuItem>
                            <MenuItem value="13-17">13-17 ans</MenuItem>
                            <MenuItem value="18-30">18-30 ans</MenuItem>
                            <MenuItem value="31-50">31-50 ans</MenuItem>
                            <MenuItem value="51-100">51+ ans</MenuItem>
                        </Select>
                    </FilterFormControl>
                </Grid>
            </Grid>

            <FilterFormControl>
                <InputLabel>Routines</InputLabel>
                <Select
                    multiple
                    value={formData.routineIds}
                    onChange={(e) => setFormData({ ...formData, routineIds: e.target.value })}
                    input={<OutlinedInput label="Routines" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => {
                                const routine = routinesData?.getAllRoutines.find(r => r.id === value);
                                return routine ? (
                                    <Chip
                                        key={value}
                                        label={routine.name}
                                        onDelete={() => {
                                            setFormData({
                                                ...formData,
                                                routineIds: formData.routineIds.filter(id => id !== value)
                                            });
                                        }}
                                        color="primary"
                                        variant="outlined"
                                    />
                                ) : null;
                            })}
                        </Box>
                    )}
                >
                    {routinesData?.getAllRoutines
                        .filter(routine =>
                            (!formData.hairType || routine.type_hair === formData.hairType) &&
                            (!formData.ageRange || isInAgeRange(routine.minAge, routine.maxAge, formData.ageRange))
                        )
                        .map((routine) => {
                            const hairTypeInfo = getHairTypeInfo(routine.type_hair);
                            return (
                                <MenuItem key={routine.id} value={routine.id}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        width: '100%'
                                    }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <ContentCutIcon fontSize="small" />
                                            <Typography>{routine.name}</Typography>
                                        </Box>
                                        <Chip
                                            size="small"
                                            label={hairTypeInfo?.name || routine.type_hair}
                                            color={getCategoryColor(hairTypeInfo?.category)}
                                        />
                                    </Box>
                                </MenuItem>
                            );
                        })
                    }
                </Select>
            </FilterFormControl>
        </Box>
    );

    return (
        <div>
            <div className='Title'>
                <Toolbar />
                <Box display="flex" justifyContent="space-between" alignItems={'center'}>
                    <h2> Liste des programmes</h2>
                    {/*<Fab color="primary" aria-label="add" onClick={() => {
                        resetForm();
                        setModalOpen(true);
                    }} sx={{ mt: -2 }}>
                        <AddIcon/>
                    </Fab>*/}
                </Box>
            </div>
            {/* <AppBar position="static" color="transparent" elevation={0} sx={{ mb: 4 }}>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
                        Liste des programmes
                    </Typography>
                    <Fab
                        color="primary"
                        variant="extended"
                        onClick={() => {
                            resetForm();
                            setModalOpen(true);
                        }}
                        sx={{ gap: 1 }}
                    >
                        <AddIcon />
                        Nouveau Programme
                    </Fab>
                </Toolbar>
            </AppBar> */}
            <Toolbar />
            <div style={{
                marginLeft: '20px',
                marginRight: '20px',
            }}>
                <SearchContainer>
                    <StyledTextField
                        fullWidth
                        type='search'
                        placeholder="Rechercher un programme..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: <SearchIcon sx={{ color: 'action.active', mr: 1 }} />,
                        }}
                    />
                </SearchContainer>

                {programmesLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid container spacing={3}>
                        {getFilteredProgrammes().map((programme) => (
                            <Grid item xs={12} md={6} key={programme.id}>
                                <EnhancedProgrammeCard>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                    }}>
                                        <ProgrammeTitle>{programme.name}</ProgrammeTitle>
                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                            <ActionButton
                                                onClick={() => handleEdit(programme)}
                                                sx={{ color: theme.palette.primary.main }}
                                            >
                                                <EditIcon />
                                            </ActionButton>
                                            <ActionButton
                                                onClick={() => handleDeleteClick(programme)}
                                                sx={{ color: theme.palette.error.main }}
                                            >
                                                <DeleteIcon />
                                            </ActionButton>
                                        </Box>
                                    </Box>

                                    <ProgrammeDescription>
                                        {programme.description}
                                    </ProgrammeDescription>

                                    <ProgrammeSection>
                                        <Typography variant="h6" gutterBottom color="primary">
                                            Challenge du moment
                                        </Typography>
                                        <Typography>
                                            {programme.challengeDuMoment}
                                        </Typography>
                                    </ProgrammeSection>

                                    {programme.routines?.length > 0 && (
                                        <ProgrammeSection>
                                            <Typography variant="h6" gutterBottom color="primary">
                                                Routines associées
                                            </Typography>
                                            <RoutineChips>
                                                {programme.routines.map((routine) => (
                                                    <Chip
                                                        key={routine.id}
                                                        label={routine.name}
                                                        variant="outlined"
                                                        color="primary"
                                                    />
                                                ))}
                                            </RoutineChips>
                                        </ProgrammeSection>
                                    )}
                                </EnhancedProgrammeCard>
                            </Grid>
                        ))}
                    </Grid>
                )}

                {/* Form Modal */}
                <Dialog
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle>
                        {isEditing ? 'Modifier le programme' : 'Créer un nouveau programme'}
                    </DialogTitle>
                    <DialogContent dividers>
                        {renderFormContent()}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setModalOpen(false)}>
                            Annuler
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={createLoading || updateLoading}
                            startIcon={createLoading || updateLoading ?
                                <CircularProgress size={20} color="inherit" /> :
                                <PublishIcon />
                            }
                        >
                            {createLoading || updateLoading ? 'En cours...' : isEditing ? 'Mettre à jour' : 'Créer'}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Delete Confirmation Modal */}
                <Dialog
                    open={deleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>
                        Confirmer la suppression
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            Êtes-vous sûr de vouloir supprimer le programme "{programmeToDelete?.name}" ?
                            Cette action est irréversible.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setDeleteDialogOpen(false)}
                            disabled={deleteLoading}
                        >
                            Annuler
                        </Button>
                        <Button
                            onClick={handleDeleteConfirm}
                            color="error"
                            variant="contained"
                            disabled={deleteLoading}
                            startIcon={deleteLoading && <CircularProgress size={20} color="inherit" />}
                        >
                            {deleteLoading ? 'Suppression...' : 'Supprimer'}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Preview Modal */}
                <Dialog
                    open={showPreview}
                    onClose={() => setShowPreview(false)}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle>
                        <Typography variant="h6" color="primary">
                            Aperçu du programme
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box sx={{ p: 2 }}>
                            <Typography variant="h4" gutterBottom>
                                {formData.name}
                            </Typography>

                            <ProgrammeSection>
                                <Typography variant="h6" color="primary" gutterBottom>
                                    Description
                                </Typography>
                                <Typography>
                                    {formData.description}
                                </Typography>
                            </ProgrammeSection>

                            <ProgrammeSection>
                                <Typography variant="h6" color="primary" gutterBottom>
                                    Challenge du moment
                                </Typography>
                                <Typography>
                                    {formData.challengeDuMoment}
                                </Typography>
                            </ProgrammeSection>

                            {formData.routineIds.length > 0 && (
                                <ProgrammeSection>
                                    <Typography variant="h6" color="primary" gutterBottom>
                                        Routines associées
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {formData.routineIds.map((routineId) => {
                                            const routine = routinesData?.getAllRoutines.find(r => r.id === routineId);
                                            if (!routine) return null;
                                            const hairTypeInfo = getHairTypeInfo(routine.type_hair);

                                            return (
                                                <Grid item xs={12} key={routine.id}>
                                                    <RoutineCard>
                                                        <Typography variant="h6" gutterBottom>
                                                            {routine.name}
                                                        </Typography>
                                                        <Grid container spacing={2} sx={{ mb: 2 }}>
                                                            <Grid item xs={12} sm={4}>
                                                                <DetailChip
                                                                    icon={<ContentCutIcon />}
                                                                    label={hairTypeInfo?.name || routine.type_hair}
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <DetailChip
                                                                    icon={<PersonIcon />}
                                                                    label={`${routine.minAge}-${routine.maxAge} ans`}
                                                                    color="secondary"
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <DetailChip
                                                                    icon={<AccessTimeIcon />}
                                                                    label={`${routine.duration} min`}
                                                                    color="info"
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Typography variant="body2">
                                                            {routine.instructions}
                                                        </Typography>
                                                    </RoutineCard>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </ProgrammeSection>
                            )}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setShowPreview(false)}
                            variant="outlined"
                        >
                            Fermer
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            disabled={createLoading || updateLoading}
                            startIcon={createLoading || updateLoading ?
                                <CircularProgress size={20} color="inherit" /> :
                                <PublishIcon />
                            }
                        >
                            {createLoading || updateLoading ? 'En cours...' : isEditing ? 'Mettre à jour' : 'Créer'}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Snackbar for notifications */}
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackbar(false)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert
                        onClose={() => setOpenSnackbar(false)}
                        severity={snackbarSeverity}
                        variant="filled"
                        sx={{
                            width: '100%',
                            borderRadius: '8px',
                            '& .MuiAlert-icon': {
                                fontSize: '24px',
                            },
                            '& .MuiAlert-message': {
                                fontSize: '1rem',
                                fontWeight: 500,
                            },
                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>

                {/* Empty state */}
                {!programmesLoading && getFilteredProgrammes().length === 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: '50vh',
                            textAlign: 'center',
                            color: 'text.secondary'
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Aucun programme trouvé
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            {searchTerm ?
                                'Essayez de modifier vos critères de recherche.' :
                                /*'Commencez par créer votre premier programme.'*/
                            ''}
                        </Typography>
                        {/*<Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            resetForm();
                            setModalOpen(true);
                        }}
                    >
                        Créer un programme
                    </Button>*/}
                    </Box>
                )}

            </div>

        </div>
    );
};

export default Programmes;