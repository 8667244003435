import React, { useState, useEffect} from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { ME_QUERY } from '../../graphql/queries';
import { UPDATE_USER_MUTATION } from '../../graphql/mutations';
import { CircularProgress, Tab, Tabs } from '@material-ui/core';
import '../../styles/Profil.css';
import logo from "../../assets/logo.png";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Box,
    CssBaseline,
    AppBar,
    Toolbar,
    List,
    Typography,
    ListItem,
    ListItemIcon,
    ListItemText,
    IconButton,
    TextField,
    Button,
    Avatar,
} from '@mui/material';
import MessagesComponent from './MessagesComponent';
import PrivacyPage from './PrivacyPage';
import PersonIcon from '@mui/icons-material/Person';
import ExtensionIcon from '@mui/icons-material/Extension';
import LogoutIcon from '@mui/icons-material/Logout';
import ResizableDrawer from "./ResizableDrawer";
import HairTypeSelector from "../HairTypeSelector";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

const UserProfile = () => {
    const navigate = useNavigate();
    const { data, loading, error } = useQuery(ME_QUERY, { fetchPolicy: 'network-only' });
    const [updateUser, { loading: updating, error: updateError }] = useMutation(UPDATE_USER_MUTATION);

    // États
    const [tabIndex, setTabIndex] = useState(0);
    const [drawerWidth, setDrawerWidth] = useState(250);
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const [activeContent, setActiveContent] = useState('profil');
    const [profileImageFile, setProfileImageFile] = useState(null);
    const [avatarPreview, setAvatarPreview] = useState(null);
    const [user, setUser] = useState({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        profileImage: '',
        typeDeCheveux: '',
        age: '',
        role: ''
    });

    const fileInputRef = React.createRef();
    const menuId = 'primary-account-menu';

    // Effets
    useEffect(() => {
        if (data?.me?.user) {
            setUser(data.me.user);
        }
    }, [data]);

    useEffect(() => {
        // Nettoyage des URLs de prévisualisation
        return () => {
            if (avatarPreview) {
                URL.revokeObjectURL(avatarPreview);
            }
        };
    }, [avatarPreview]);

    // Gestionnaires d'événements
    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUser(prev => ({ ...prev, [name]: value }));
    };

    const handleHairTypeChange = (event) => {
        setUser(prev => ({ ...prev, typeDeCheveux: event.target.value }));
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleShowProfil = () => setActiveContent('profil');
    const handleShowConfidentialite = () => setActiveContent('confidentialité');

    const handleFileInputChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfileImageFile(file);
            const previewUrl = URL.createObjectURL(file);
            setAvatarPreview(previewUrl);
        }
    };

    const handleDrop = async (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
            setProfileImageFile(file);
            const previewUrl = URL.createObjectURL(file);
            setAvatarPreview(previewUrl);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleClickOnDropzone = () => {
        fileInputRef.current?.click();
    };

    const uploadImageToYourService = async (file) => {
        // Implémentez votre logique de téléchargement d'image ici
        // Retournez l'URL de l'image téléchargée
        return 'URL_de_votre_image_téléchargée';
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            let imageUrl = user.profileImage;

            if (profileImageFile) {
                imageUrl = await uploadImageToYourService(profileImageFile);
            }

            const updatedUser = {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                profileImage: imageUrl,
                typeDeCheveux: user.typeDeCheveux,
                age: parseInt(user.age),
                role: user.role
            };

            const { data: updateData } = await updateUser({
                variables: updatedUser
            });

            if (updateData) {
                alert('Profil mis à jour avec succès !');
                // Mise à jour de l'avatar dans l'état local si nécessaire
                if (profileImageFile) {
                    setUser(prev => ({ ...prev, profileImage: imageUrl }));
                }
            }
        } catch (err) {
            console.error('Erreur lors de la mise à jour:', err);
            alert('Erreur lors de la mise à jour du profil. Veuillez réessayer.');
        }
    };

    // Composant de profil utilisateur
    const UserProfil = () => (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'flex-start',
            gap: '2rem',
            padding: '2rem'
        }}>
            <Box sx={{ width: '300px' }}>
                <form onSubmit={handleSubmit}>
                    <input
                        type="file"
                        accept="image/*"
                        name="profileImage"
                        onChange={handleFileInputChange}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                    />
                    <TextField
                        label="Prénom"
                        name="firstName"
                        value={user.firstName}
                        onChange={handleChange}
                        variant="standard"
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Nom"
                        name="lastName"
                        value={user.lastName}
                        onChange={handleChange}
                        variant="standard"
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        name="email"
                        value={user.email}
                        onChange={handleChange}
                        variant="standard"
                        fullWidth
                        margin="normal"
                    />
                    <HairTypeSelector
                        value={user.typeDeCheveux}
                        onChange={handleHairTypeChange}
                    />
                    <TextField
                        label="Age"
                        name="age"
                        type="number"
                        value={user.age}
                        onChange={handleChange}
                        variant="standard"
                        fullWidth
                        margin="normal"
                        InputProps={{
                            inputProps: {
                                min: 13,
                                max: 120
                            }
                        }}
                        sx={{
                            '& .MuiInputBase-root': {
                                background: 'rgba(255, 255, 255, 0.9)',
                                borderRadius: '4px',
                            },
                            '& .MuiInputBase-input': {
                                padding: '10px 14px',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#6A0D47',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#6A0D47',
                                borderWidth: '2px',
                            },
                            '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#6A0D47',
                            }
                        }}
                    />
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={updating}
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Mettre à jour le profil
                    </Button>
                    {updating && <CircularProgress size={24} />}
                    {updateError && <Typography color="error">{updateError.message}</Typography>}
                </form>
            </Box>
            <Box
                className="dropzone"
                onClick={handleClickOnDropzone}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                sx={{
                    width: '300px',
                    border: '2px dashed #ccc',
                    borderRadius: '5px',
                    padding: '20px',
                    textAlign: 'center',
                    cursor: 'pointer'
                }}
            >
                {avatarPreview ? (
                    <img
                        src={avatarPreview}
                        alt="Profile Preview"
                        style={{ maxWidth: '100%', maxHeight: '200px' }}
                    />
                ) : (
                    <img
                        src={user.profileImage || '/default-profile-image.jpg'}
                        alt="Profile"
                        style={{ maxWidth: '100%', maxHeight: '200px' }}
                    />
                )}
                <Typography sx={{ mt: 2 }}>Changer la photo de profil</Typography>
            </Box>
        </Box>
    );

    // Sélection du composant à afficher
    let ContentComponent;
    switch (activeContent) {
        case 'profil':
            ContentComponent = UserProfil;
            break;
        case 'messages':
            ContentComponent = MessagesComponent;
            break;
        case 'confidentialité':
            ContentComponent = PrivacyPage;
            break;
        default:
            ContentComponent = UserProfil;
    }

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Erreur : {error.message}</Typography>;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${drawerWidth}px)`,
                    ml: `${drawerWidth}px`,
                    backgroundColor: '#6A0D47',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
            >
                <Toolbar>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        Mon profil utilisateur
                    </Typography>
                    <IconButton
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <img
                            src={logo}
                            alt="Logo Groomed Hair"
                            className="logo"
                            style={{ width: '100px', height: 'auto' }}
                        />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <ResizableDrawer
                drawerWidth={drawerWidth}
                setDrawerWidth={setDrawerWidth}
                isOpen={isDrawerOpen}
                handleDrawerClose={() => setIsDrawerOpen(!isDrawerOpen)}
            >
                <Toolbar />
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <IconButton onClick={() => navigate('/dashboard')}>
                        <ArrowBackIcon sx={{ color: 'white' }} />
                    </IconButton>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                    <Avatar
                        alt={user.firstName}
                        src={avatarPreview || user.profileImage}
                        sx={{ width: 150, height: 150 }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
                    mx: 3,
                    pb: 2
                }}>
                    <Typography variant="h6" sx={{ color: 'white' }}>
                        {user.firstName}
                    </Typography>
                </Box>

                <List sx={{ mt: 2 }}>
                    <ListItem button onClick={handleShowProfil}>
                        <ListItemIcon>
                            <PersonIcon sx={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText primary="Mon compte" sx={{ color: 'white' }} />
                    </ListItem>
                    <ListItem button onClick={handleShowConfidentialite}>
                        <ListItemIcon>
                            <ExtensionIcon sx={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText primary="Confidentialité" sx={{ color: 'white' }} />
                    </ListItem>
                    <ListItem button onClick={handleLogout}>
                        <ListItemIcon>
                            <LogoutIcon sx={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText primary="Déconnexion" sx={{ color: 'white' }} />
                    </ListItem>
                </List>
            </ResizableDrawer>

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    width: `calc(100% - ${drawerWidth}px)`,
                    minHeight: '100vh',
                    backgroundColor: '#f4f5f7',
                    marginTop: '94px',
                    transition: 'width 0.2s ease',
                    overflow: 'hidden'
                }}
            >
                <ContentComponent />
            </Box>
        </Box>
    );
};

export default UserProfile;