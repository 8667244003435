import React, { useState } from "react";
import { Box, Grid, Paper, Typography, Toolbar } from "@mui/material";
import Messagerie from "./Messagerie"; // Liste des conversations
import Discussion from "./Discussion"; // Section discussion

const ChatApp = () => {
    const [selectedConversation, setSelectedConversation] = useState(null);

    // Fonction appelée lorsque l'utilisateur sélectionne une conversation
    const handleSelectConversation = (conversation) => {
        setSelectedConversation(conversation);
    };

    return (
        <Box>
            <Toolbar />
            <div className='Title'>
                <Box display="flex" justifyContent="space-between" alignItems={'center'}>
                    <h2>Liste des discussions</h2>
                </Box>
            </div>
            <Box
                sx={{
                    height: "100vh", // Toute la hauteur de la fenêtre
                    display: "flex",
                    backgroundColor: "#f5f5f5", // Couleur de fond de l'application
                }}
            >
            <Grid container sx={{ flexGrow: 1 }}>
                {/* Colonne gauche : liste des conversations */}
                <Grid
                    item
                    xs={4}
                    sx={{
                        height: "100%",
                        overflowY: "auto", // Permet le défilement de la liste
                        borderRight: "1px solid #ddd",
                        backgroundColor: "#fff",
                    }}
                >
                    <Messagerie
                        onSelectConversation={handleSelectConversation}
                    />
                </Grid>

                {/* Colonne droite : discussion */}
                <Grid
                    item
                    xs={8}
                    sx={{
                        height: "100%", // Fixe toute la hauteur
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {selectedConversation ? (
                        <Discussion conversation={selectedConversation} />
                    ) : (
                        <Paper
                            sx={{
                                flexGrow: 1,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#fff",
                            }}
                        >
                            <Typography variant="h6" color="textSecondary">
                                Sélectionnez une conversation pour commencer
                            </Typography>
                        </Paper>
                    )}
                </Grid>
            </Grid>
        </Box>
        </Box>
    );
};

export default ChatApp;
