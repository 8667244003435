import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField, Grid, Typography } from '@mui/material';

// Définition des tranches d'âge prédéfinies
export const AGE_RANGES = [
    { id: 1, label: "0-10 ans", min: 0, max: 10 },
    { id: 2, label: "10-17 ans", min: 10, max: 17 },
    { id: 3, label: "18-25 ans", min: 18, max: 25 },
    { id: 4, label: "26-35 ans", min: 26, max: 35 },
    { id: 5, label: "36-45 ans", min: 36, max: 45 },
    { id: 6, label: "46-55 ans", min: 46, max: 55 },
    { id: 7, label: "56-65 ans", min: 56, max: 65 },
    { id: 8, label: "66-75 ans", min: 66, max: 75 },
    { id: 9, label: "76-85 ans", min: 76, max: 85 },
    { id: 10, label: "86-100 ans", min: 86, max: 100 },
    { id: 'custom', label: "Personnalisé", min: null, max: null }, // Changed id to 'custom'
];

const AgeRangeSelector = ({
                              minAge,
                              maxAge,
                              onAgeRangeChange,
                              error = false,
                              helperText = ""
                          }) => {
    const [selectedRange, setSelectedRange] = React.useState('custom');

    // Trouve la tranche d'âge correspondante aux valeurs actuelles
    React.useEffect(() => {
        const currentRange = AGE_RANGES.find(
            range => range.min === minAge && range.max === maxAge
        );
        setSelectedRange(currentRange ? currentRange.id : 'custom');
    }, [minAge, maxAge]);

    // Gestion du changement de tranche d'âge prédéfinie
    const handleRangeChange = (event) => {
        const rangeId = event.target.value;
        setSelectedRange(rangeId);

        if (rangeId === 'custom') {
            // Si "Personnalisé" est sélectionné, ne pas changer les valeurs actuelles
            return;
        }

        const selectedRange = AGE_RANGES.find(range => range.id === rangeId);
        if (selectedRange) {
            onAgeRangeChange(selectedRange.min, selectedRange.max);
        }
    };

    // Gestion du changement manuel des âges
    const handleCustomAgeChange = (type) => (event) => {
        const value = parseInt(event.target.value) || 0;
        if (type === 'min') {
            onAgeRangeChange(value, maxAge);
        } else {
            onAgeRangeChange(minAge, value);
        }
        setSelectedRange('custom');
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel id="age-range-select-label">
                        Tranche d'âge
                    </InputLabel>
                    <Select
                        labelId="age-range-select-label"
                        id="age-range-select"
                        value={selectedRange}
                        label="Tranche d'âge"
                        onChange={handleRangeChange}
                    >
                        {AGE_RANGES.map((range) => (
                            <MenuItem key={range.id} value={range.id}>
                                {range.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <Typography variant="caption" color="textSecondary" sx={{ mb: 1 }}>
                    {selectedRange === 'custom' ? "Définir une tranche d'âge personnalisée :" : "Ou ajuster manuellement :"}
                </Typography>
            </Grid>

            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="Âge minimum"
                    type="number"
                    value={minAge}
                    onChange={handleCustomAgeChange('min')}
                    error={error}
                    helperText={error ? helperText : ''}
                    InputProps={{
                        inputProps: {
                            min: 0,
                            max: maxAge || 100
                        }
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    label="Âge maximum"
                    type="number"
                    value={maxAge}
                    onChange={handleCustomAgeChange('max')}
                    error={error}
                    helperText={error ? helperText : ''}
                    InputProps={{
                        inputProps: {
                            min: minAge || 0,
                            max: 100
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default AgeRangeSelector;