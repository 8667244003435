// src/components/styles/HairTypeStyle.js

import { styled } from '@mui/material/styles';
import { FormControl, MenuItem, Typography, Box } from '@mui/material';

export const HairTypeSelect = styled(FormControl)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: '12px',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }
    },
    '& .MuiMenuItem-root': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    }
}));

export const HairTypeMenuItem = styled(MenuItem)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(1.5, 2),
    borderRadius: theme.spacing(1),
    '&:not(:last-child)': {
        marginBottom: theme.spacing(0.5),
    },
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-selected': {
        backgroundColor: `${theme.palette.primary.light}20`,
        '&:hover': {
            backgroundColor: `${theme.palette.primary.light}30`,
        }
    }
}));

export const HairTypeBox = styled(Box)(({ theme }) => ({
    width: '100%',
}));

export const HairTypeName = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    fontWeight: 500,
}));

export const HairTypeDescription = styled(Typography)(({ theme }) => ({
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
    lineHeight: 1.4,
}));

export const CategoryChip = styled(Box)(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    padding: '3px 8px',
    borderRadius: '12px',
    fontSize: '0.75rem',
    fontWeight: 500,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(1),
}));

export const HairTypeInfoBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& .MuiChip-root': {
        height: '24px',
        '& .MuiChip-label': {
            fontSize: '0.75rem',
            padding: '0 8px',
        }
    }
}));