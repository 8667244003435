import React, { useState } from 'react';
import {
    Box, Typography, Card, CardContent, CardMedia, IconButton,
    Grid, Button, CircularProgress, Alert, TextField, Dialog,
    DialogTitle, DialogContent, DialogActions, Pagination,
    InputAdornment, Toolbar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    Delete as DeleteIcon, ExpandMore as ExpandMoreIcon,
    ExpandLess as ExpandLessIcon, Announcement as AnnouncementIcon,
    Image as ImageIcon, Search as SearchIcon, Edit as EditIcon,
    Save as SaveIcon, CloudUpload as CloudUploadIcon
} from '@mui/icons-material';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_ANNONCES } from '../../graphql/queries';
import { DELETE_ANNONCE, UPDATE_ANNONCE } from '../../graphql/mutations';

const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: theme.shadows[4],
    },
}));

const MediaContainer = styled(Box)(({ theme }) => ({
    width: 200,
    minWidth: 200,
    height: '50%',
    position: 'relative',
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
}));

const ContentContainer = styled(CardContent)(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '&:last-child': {
        paddingBottom: theme.spacing(2),
    },
}));

const SearchBar = styled(TextField)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    '& .MuiOutlinedInput-root': {
        borderRadius: theme.shape.borderRadius,
    },
}));

const PaginationContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
}));

const ITEMS_PER_PAGE = 5;

const AnnouncementsSection = () => {
    const [expandedId, setExpandedId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [annonceToDelete, setAnnonceToDelete] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [editingAnnonce, setEditingAnnonce] = useState(null);

    const { loading, error, data, refetch } = useQuery(GET_ALL_ANNONCES);
    const [deleteAnnonce] = useMutation(DELETE_ANNONCE, {
        refetchQueries: [{ query: GET_ALL_ANNONCES }]
    });
    const [updateAnnonce] = useMutation(UPDATE_ANNONCE, {
        refetchQueries: [{ query: GET_ALL_ANNONCES }]
    });

    const compressImage = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const MAX_WIDTH = 800;
                    const MAX_HEIGHT = 600;
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    resolve(canvas.toDataURL('image/jpeg', 0.7));
                };
            };
        });
    };

    const handleMediaChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const compressedBase64 = await compressImage(file);
                setEditingAnnonce(prev => ({
                    ...prev,
                    media: compressedBase64
                }));
            } catch (error) {
                console.error('Error compressing image:', error);
            }
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(1);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleDeleteClick = (annonce) => {
        setAnnonceToDelete(annonce);
        setDeleteDialogOpen(true);
    };

    const handleEditClick = (annonce) => {
        setEditingAnnonce(annonce);
        setOpenEditDialog(true);
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteAnnonce({ variables: { id: annonceToDelete.id } });
            setDeleteDialogOpen(false);
            setAnnonceToDelete(null);
        } catch (err) {
            console.error('Error deleting announcement:', err);
        }
    };

    const handleUpdateAnnonce = async () => {
        try {
            await updateAnnonce({
                variables: {
                    id: editingAnnonce.id,
                    title: editingAnnonce.title,
                    description: editingAnnonce.description,
                    media: editingAnnonce.media || ''
                }
            });
            setOpenEditDialog(false);
            setEditingAnnonce(null);
        } catch (error) {
            console.error('Error updating announcement:', error);
        }
    };

    const filteredAnnonces = data?.getAllAnnonces
    ?.slice() // Crée une copie du tableau
    ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    ?.filter(annonce =>
        annonce.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        annonce.description.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];


    const totalPages = Math.ceil(filteredAnnonces.length / ITEMS_PER_PAGE);
    const paginatedAnnonces = filteredAnnonces.slice(
        (page - 1) * ITEMS_PER_PAGE,
        page * ITEMS_PER_PAGE
    );

    if (loading) return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight={400}>
            <CircularProgress />
        </Box>
    );

    if (error) return (
        <Alert severity="error" sx={{ mb: 2 }}>
            Une erreur est survenue lors du chargement des annonces
        </Alert>
    );

    return (
        <Box>
            <SearchBar
                fullWidth
                variant="outlined"
                placeholder="Rechercher dans les annonces..."
                value={searchTerm}
                type='search'
                onChange={handleSearchChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            <Toolbar />
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                <AnnouncementIcon color="primary" sx={{ mr: 2 }} />
                <Typography variant="h5" component="h2" color="primary">
                    Annonces Publiées
                </Typography>
            </Box>

            <Grid container spacing={2}>
                {paginatedAnnonces.map((annonce) => (
                    <Grid item xs={12} key={annonce.id}>
                        <StyledCard>
                            <MediaContainer>
                                {annonce.media ? (
                                    <img src={annonce.media} alt={annonce.title} />
                                ) : (
                                    <ImageIcon sx={{ fontSize: 60, color: 'grey.400' }} />
                                )}
                            </MediaContainer>
                            <ContentContainer>
                                <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={1}>
                                    <Typography variant="h6" component="h2">
                                        {annonce.title}
                                    </Typography>
                                    <Box>
                                        <IconButton
                                            onClick={() => handleEditClick(annonce)}
                                            size="small"
                                            color="primary"
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() => handleDeleteClick(annonce)}
                                            size="small"
                                            color="error"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                </Box>

                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        mb: 1,
                                        ...(expandedId !== annonce.id && {
                                            display: '-webkit-box',
                                            WebkitLineClamp: 3,
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden'
                                        })
                                    }}
                                >
                                    {annonce.description}
                                </Typography>

                                {annonce.description.length > 150 && (
                                    <Button
                                        onClick={() => setExpandedId(expandedId === annonce.id ? null : annonce.id)}
                                        startIcon={expandedId === annonce.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        size="small"
                                        sx={{ mt: 'auto' }}
                                    >
                                        {expandedId === annonce.id ? 'Voir moins' : 'Voir plus'}
                                    </Button>
                                )}
                            </ContentContainer>
                        </StyledCard>
                    </Grid>
                ))}
            </Grid>

            {filteredAnnonces.length === 0 ? (
                <Box display="flex" flexDirection="column" alignItems="center" py={8}>
                    <AnnouncementIcon sx={{ fontSize: 60, color: 'grey.400', mb: 2 }} />
                    <Typography color="text.secondary">
                        Aucune annonce disponible
                    </Typography>
                </Box>
            ) : (
                <PaginationContainer>
                    <Typography color="text.secondary">
                        {`${filteredAnnonces.length} annonce${filteredAnnonces.length > 1 ? 's' : ''} • 
                         Page ${page} sur ${totalPages}`}
                    </Typography>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        size="large"
                    />
                </PaginationContainer>
            )}

            {/* Delete Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Confirmer la suppression</DialogTitle>
                <DialogContent>
                    <Typography>
                        Êtes-vous sûr de vouloir supprimer l'annonce "{annonceToDelete?.title}" ?
                        Cette action est irréversible.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>
                        Annuler
                    </Button>
                    <Button onClick={handleConfirmDelete} color="error" variant="contained">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Edit Dialog */}
            <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} fullWidth maxWidth="md">
                <DialogTitle>Modifier l'annonce</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            label="Titre de l'annonce"
                            fullWidth
                            value={editingAnnonce?.title || ''}
                            onChange={(e) => setEditingAnnonce({
                                ...editingAnnonce,
                                title: e.target.value
                            })}
                        />
                        <TextField
                            label="Contenu de l'annonce"
                            fullWidth
                            multiline
                            rows={4}
                            value={editingAnnonce?.description || ''}
                            onChange={(e) => setEditingAnnonce({
                                ...editingAnnonce,
                                description: e.target.value
                            })}
                        />
                        <Button
                            component="label"
                            variant="outlined"
                            startIcon={<CloudUploadIcon />}
                        >
                            {editingAnnonce?.media ? 'Changer le média' : 'Ajouter un média'}
                            <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={handleMediaChange}
                            />
                        </Button>

                        {editingAnnonce?.media && (
                            <Box sx={{
                                width: '100%',
                                maxWidth: 300,
                                height: 150,
                                margin: '0 auto',
                                mt: 2,
                                position: 'relative',
                                '& img': {
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: 1
                                }
                            }}>
                                <img src={editingAnnonce.media} alt="Aperçu" />
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditDialog(false)}>Annuler</Button>
                    <Button
                        variant="contained"
                        onClick={handleUpdateAnnonce}
                        startIcon={<SaveIcon />}
                        disabled={!editingAnnonce?.title || !editingAnnonce?.description}
                    >
                        Enregistrer
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default AnnouncementsSection;