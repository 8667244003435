//queries.js

import gql from 'graphql-tag';

export const ME_QUERY = gql`
    query Me {
        me {
            user {
                id
                mongoId
                firstName
                lastName
                age
                email
                profileImage
                mdp
                typeDeCheveux
                role
            }
        }
    }
`;

export const CONVERSATIONS_QUERY = gql`
    query Conversations($userId: String!) {
        conversations(userId: $userId) {
            id
            mongoId
            participants {
                id
                mongoId
                firstName
                lastName
                profileImage
                role
            }
            messages {
                id
                mongoId
                content
                timestamp
                read
                sender {
                    id
                    mongoId
                    firstName
                    lastName
                    role
                }
                readBy {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;


export const ALL_CONVERSATIONS_QUERY = gql`
    query AllConversations {
        allConversations {
            id
            mongoId
            participants {
                id
                mongoId
                firstName
                lastName
                profileImage
                role
            }
            messages {
                id
                mongoId
                content
                timestamp
                read
                sender {
                    id
                    mongoId
                    firstName
                    lastName
                }
                readBy {
                    id
                    mongoId
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const MESSAGES_QUERY = gql`
    query Messages($conversationId: ID!) {
        messages(conversationId: $conversationId) {
            id
            mongoId
            content
            timestamp
            sender {
                id
                mongoId
                firstName
                lastName
                role
            }
        }
    }
`;


export const GET_MESSAGES_QUERY = gql`
    query GetMessages($conversationId: ID!) {
        messages(conversationId: $conversationId) {
            id
            content
            timestamp
            read
            fileUrl
            fileType
            readBy {
                id
                firstName
                lastName
                mongoId
            }
            sender {
                id
                firstName
                lastName
                mongoId
                role
            }
        }
    }
`;



export const CREATE_MESSAGE_MUTATION = gql`
    mutation CreateMessage($conversationId: ID!, $content: String!, $file: Upload, $fileType: String) {
        createMessage(conversationId: $conversationId, content: $content, file: $file, fileType: $fileType) {
            id
            mongoId
            content
            timestamp
            fileUrl
            fileType
            sender {
                id
                mongoId
                firstName
                lastName
                role
            }
        }
    }
`;

export const MARK_AS_READ_MUTATION = gql`
    mutation MarkMessagesAsRead($conversationId: ID!) {
        markMessagesAsRead(conversationId: $conversationId) {
            success
        }
    }
`;

export const GET_ALL_PROGRAMMES = gql`
    query GetAllProgramm {
        getAllProgramm {
            id
            name
            description
            challengeDuMoment
        }
    }
`;

export const GET_ALL_ANNONCES = gql`
    query GetAllAnnonces {
        getAllAnnonces {
            id
            title
            media
            description
            createdAt
        }
    }
`;

export const GET_ALL_USERS_ACTIVITY = gql`
    query {
        getAllUsersActivity {
            id
            date
            timeLoggedIn
            day
            month
            year
            pageVisited

        }
    }
`;


