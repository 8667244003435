// src/constants/hairTypes.js

export const HAIR_TYPES = [
    {
        id: '2A',
        name: 'Type 2A',
        description: 'Cheveux légèrement ondulés, souvent fins et faciles à coiffer. Ils réagissent bien à des produits légers.',
        category: 'Ondulés'
    },
    {
        id: '2B',
        name: 'Type 2B',
        description: 'Ondulations plus marquées, nécessitant des soins pour définir et maintenir les vagues sans frisottis.',
        category: 'Ondulés'
    },
    {
        id: '2C',
        name: 'Type 2C',
        description: 'Cheveux très ondulés, presque bouclés. Nécessite des soins nourrissants pour éviter les frisottis et maintenir les vagues définies.',
        category: 'Ondulés'
    },
    {
        id: '3A',
        name: 'Type 3A',
        description: 'Cheveux légèrement bouclés. Ils nécessitent des soins légers pour maintenir les boucles sans les alourdir.',
        category: 'Bouclés'
    },
    {
        id: '3B',
        name: 'Type 3B',
        description: 'Boucles larges et définies. Elles nécessitent des soins hydratants pour éviter les frisottis et maintenir leur forme.',
        category: 'Bouclés'
    },
    {
        id: '3C',
        name: 'Type 3C',
        description: 'Boucles très serrées, volumineuses et définies. Ces cheveux nécessitent une hydratation régulière et des produits anti-frisottis.',
        category: 'Bouclés'
    },
    {
        id: '4A',
        name: 'Type 4A',
        description: 'Cheveux crépus avec des boucles bien définies. Ils nécessitent des soins hydratants pour éviter la sécheresse.',
        category: 'Crépus'
    },
    {
        id: '4B',
        name: 'Type 4B',
        description: 'Cheveux très crépus avec des frisures moyennement serrées. Ils nécessitent des soins nourrissants et une manipulation douce.',
        category: 'Crépus'
    },
    {
        id: '4C',
        name: 'Type 4C',
        description: 'Cheveux très crépus avec des frisures serrées. Très fragiles, ils nécessitent une hydratation intense et une manipulation douce.',
        category: 'Crépus'
    },
    {
        id: 'MIXED',
        name: 'Type Mixte',
        description: 'Pour ceux qui ne connaissent pas encore leur type de cheveux ou qui ont plusieurs types.',
        category: 'Mixte'
    }
];

export const getHairTypeInfo = (typeId) => HAIR_TYPES.find(type => type.id === typeId);

export const getCategoryColor = (category) => {
    switch (category) {
        case 'Crépus':
            return 'primary';
        case 'Bouclés':
            return 'secondary';
        case 'Ondulés':
            return 'info';
        default:
            return 'default';
    }
};