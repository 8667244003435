
import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Header.css';
import logo from '../../assets/logo.png';

function Header() {
    return (
        <header className="header">
            <div className="header-brand">
                <Link to="/">
                    <img src={logo} alt="Logo Groomed Hair" className="logo" />
                </Link>
            </div>

        </header>
    );
}

export default Header;
