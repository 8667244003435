import React, {useMemo, useState} from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Select from 'react-select';
import {
    Box, Button, TextField, Card, CardContent,
    Typography, Grid, CardActions, Toolbar,
    Dialog, DialogActions, DialogContent, DialogTitle, Fab,
    IconButton, Pagination, DialogContentText, Paper,
    Chip, Container, AppBar, InputAdornment, Divider, useTheme, alpha
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import { GET_ALL_ROUTINES, GET_ALL_PRODUCTS, CREATE_ROUTINE, UPDATE_ROUTINE, DELETE_ROUTINE } from '../../graphql/mutations';
import HairTypeSelector from '../../components/HairTypeSelector';
import { HAIR_TYPES, getHairTypeInfo, getCategoryColor } from '../../constants/hairTypes';
import AgeRangeSelector from "../../constants/AgeRange";

const RoutineDashboard = () => {
    const theme = useTheme();
    const { loading, error, data, refetch } = useQuery(GET_ALL_ROUTINES);
    const [createRoutine] = useMutation(CREATE_ROUTINE, { onCompleted: () => refetch() });
    const [updateRoutine] = useMutation(UPDATE_ROUTINE, { onCompleted: () => refetch() });
    const [deleteRoutine] = useMutation(DELETE_ROUTINE, { onCompleted: () => refetch() });
    const { data: productsData } = useQuery(GET_ALL_PRODUCTS);

    const [formData, setFormData] = useState({
        name: '',
        instructions: '',
        type_hair: '',
        minAge: 0,
        maxAge: 0,
        duration: 0,
        produits: [],
    });

    const [editingId, setEditingId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteCandidate, setDeleteCandidate] = useState(null);
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    const cardStyles = {
        root: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
            '&:hover': {
                transform: 'translateY(-4px)',
                boxShadow: theme.shadows[8],
            },
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginBottom: theme.spacing(2),
        },
        title: {
            fontWeight: 600,
            fontSize: '1.1rem',
            color: theme.palette.text.primary,
            marginBottom: theme.spacing(0.5),
        },
        label: {
            fontWeight: 600,
            color: theme.palette.primary.main,
            marginBottom: theme.spacing(0.5),
        },
        chip: {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            color: theme.palette.primary.main,
            fontWeight: 500,
        },
        infoSection: {
            marginBottom: theme.spacing(2),
        },
        infoRow: {
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            marginBottom: theme.spacing(1),
            color: theme.palette.text.secondary,
        },
        instructions: {
            color: theme.palette.text.secondary,
            fontSize: '0.9rem',
            lineHeight: 1.6,
        },
        actions: {
            padding: theme.spacing(2),
            justifyContent: 'flex-end',
            gap: theme.spacing(1),
        },
    };

    const handleHairTypeChange = (event) => {
        setFormData({
            ...formData,
            type_hair: event.target.value
        });
    };

    const handleEdit = routine => {
        setEditingId(routine.id);
        setFormData({
            name: routine.name,
            instructions: routine.instructions,
            type_hair: routine.type_hair,
            minAge: routine.minAge,
            maxAge: routine.maxAge,
            duration: routine.duration,
            produits: routine.produits.map(p => p.id),
        });
        setModalOpen(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const startTime = new Date().toISOString();
        const endTime = new Date(new Date().getTime() + formData.duration * 60000).toISOString();

        const variables = {
            ...formData,
            startTime,
            endTime,
            produits: formData.produits,
            duration: parseInt(formData.duration, 10),
            minAge: parseInt(formData.minAge, 10),
            maxAge: parseInt(formData.maxAge, 10),
        };

        try {
            const mutation = editingId ? updateRoutine : createRoutine;
            await mutation({
                variables: editingId ? { id: editingId, ...variables } : variables
            });
            setModalOpen(false);
            resetForm();
        } catch (error) {
            console.error("Erreur lors de la gestion de la routine :", error);
        }
    };

    const handleDeleteConfirmation = async () => {
        await deleteRoutine({ variables: { id: deleteCandidate } });
        setDeleteDialogOpen(false);
        setDeleteCandidate(null);
    };

    const resetForm = () => {
        setFormData({
            name: '',
            instructions: '',
            type_hair: '',
            duration: 0,
            minAge: 0,
            produits: [],
        });
        setEditingId(null);
    };

    // Remplacez la section du filtrage existante par celle-ci :
    const filteredRoutines = useMemo(() => {
        if (!data?.getAllRoutines) return [];

        const sortedRoutines = [...data.getAllRoutines]
            .sort((a, b) => {
                // Si vous avez un champ createdAt
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return dateB - dateA;
            });

        return sortedRoutines.filter((routine) =>
            routine.name.toLowerCase().includes(filter.toLowerCase())
        );
    }, [data, filter]);

    const paginatedRoutines = filteredRoutines.slice(
        (currentPage - 1) * itemsPerPage,
        (currentPage * itemsPerPage)
    );

    if (loading) return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
            <Typography variant="h6" color="textSecondary">
                Chargement...
            </Typography>
        </Box>
    );

    if (error) return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
            <Typography variant="h6" color="error">
                Erreur: {error.message}
            </Typography>
        </Box>
    );
    return (
        <div maxWidth="xl">
            <Toolbar />
            <div className='Title'>
                <Box display="flex" justifyContent="space-between" alignItems={'center'}>
                    <h2>Gestion des routines</h2>
                    <Fab
                        color="primary"
                        variant="add"
                        onClick={() => {
                            resetForm();
                            setModalOpen(true);
                        }}
                    >
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>

            {/* <AppBar position="static" color="transparent" elevation={0} sx={{ mb: 4 }}>
                <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold' }}>
                        Routines
                    </Typography>
                    <Fab
                        color="primary"
                        variant="extended"
                        onClick={() => {
                            resetForm();
                            setModalOpen(true);
                        }}
                        sx={{ gap: 1 }}
                    >
                        <AddIcon />
                        Nouvelle Routine
                    </Fab>
                </Toolbar>
            </AppBar> */}

            <Paper
                elevation={0}
                sx={{
                    p: 2,
                    mb: 4,
                    backgroundColor: alpha(theme.palette.primary.main, 0.04),
                    borderRadius: 2,
                }}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    type='search'
                    placeholder="Rechercher une routine..."
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="action" />
                            </InputAdornment>
                        ),
                    }}
                    sx={{ backgroundColor: 'white' }}
                />
            </Paper>
            <div style={{
                marginLeft: '20px',
                marginRight: '20px',
            }}>
                <Grid container spacing={3}>
                    {paginatedRoutines.map(routine => (
                        <Grid item xs={12} sm={6} md={4} key={routine.id}>
                            <Card sx={cardStyles.root}>
                                <CardContent sx={cardStyles.content}>
                                    <Box sx={cardStyles.header}>
                                        <Box>
                                            <Typography sx={cardStyles.label}>
                                                Nom de la routine :
                                            </Typography>
                                            <Typography sx={cardStyles.title}>
                                                {routine.name}
                                            </Typography>
                                        </Box>
                                        {routine.type_hair && (() => {
                                            const hairType = getHairTypeInfo(routine.type_hair);
                                            return (
                                                <Chip
                                                    label={hairType?.name || routine.type_hair}
                                                    sx={cardStyles.chip}
                                                    size="small"
                                                    color={hairType ? getCategoryColor(hairType.category) : 'default'}
                                                />
                                            );
                                        })()}
                                    </Box>


                                    <Box sx={cardStyles.infoSection}>
                                        <Box sx={cardStyles.infoRow}>
                                            <AccessTimeIcon fontSize="small" />
                                            <Typography>Durée : {routine.duration} minutes</Typography>
                                        </Box>

                                        <Box sx={cardStyles.infoRow}>
                                            <PersonIcon fontSize="small" />
                                            <Typography>
                                                Tranche d'âge : {routine.minAge || 0} - {routine.maxAge || 100} ans
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Divider sx={{ my: 2 }} />

                                    <Box sx={cardStyles.infoSection}>
                                        <Typography sx={cardStyles.label}>
                                            Description :
                                        </Typography>
                                        <Typography sx={cardStyles.instructions}>
                                            {routine.instructions}
                                        </Typography>
                                    </Box>

                                    <Box sx={cardStyles.infoSection}>
                                        <Typography sx={cardStyles.label}>
                                            Produits :
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                            {routine.produits.map((produit) => (
                                                <Chip
                                                    key={produit.id}
                                                    label={produit.name}
                                                    size="small"
                                                    variant="outlined"
                                                    color="primary"
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                </CardContent>

                                <CardActions sx={cardStyles.actions}>
                                    <Button
                                        variant="outlined"
                                        startIcon={<EditIcon />}
                                        onClick={() => handleEdit(routine)}
                                        size="small"
                                    >
                                        Modifier
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="error"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => {
                                            setDeleteCandidate(routine.id);
                                            setDeleteDialogOpen(true);
                                        }}
                                        size="small"
                                    >
                                        Supprimer
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                {filteredRoutines.length > itemsPerPage && (
                    <Box display="flex" justifyContent="center" mt={4} mb={4}>
                        <Pagination
                            count={Math.ceil(filteredRoutines.length / itemsPerPage)}
                            page={currentPage}
                            onChange={(e, page) => setCurrentPage(page)}
                            color="primary"
                            size="large"
                        />
                    </Box>
                )}

                {/* Form Modal */}
                <Dialog
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>
                        {editingId ? 'Modifier la routine' : 'Créer une routine'}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                            <TextField
                                autoFocus
                                fullWidth
                                label="Nom de la routine"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                sx={{ mb: 3 }}
                            />

                            <TextField
                                fullWidth
                                label="Instructions"
                                multiline
                                rows={4}
                                value={formData.instructions}
                                onChange={(e) => setFormData({ ...formData, instructions: e.target.value })}
                                sx={{ mb: 3 }}
                            />

                            <Grid container spacing={2} sx={{ mb: 3 }}>
                                <Grid item xs={12} sm={6}>
                                    <HairTypeSelector
                                        value={formData.type_hair}
                                        onChange={handleHairTypeChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <AgeRangeSelector
                                        minAge={formData.minAge}
                                        maxAge={formData.maxAge}
                                        onAgeRangeChange={(min, max) => {
                                            setFormData(prev => ({
                                                ...prev,
                                                minAge: min,
                                                maxAge: max
                                            }));
                                        }}
                                        error={formData.maxAge < formData.minAge}
                                        helperText={
                                            formData.maxAge < formData.minAge
                                                ? "L'âge maximum doit être supérieur à l'âge minimum"
                                                : ""
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Age minimum"
                                        type="number"
                                        value={formData.minAge}
                                        onChange={(e) => setFormData({ ...formData, minAge: parseInt(e.target.value) })}
                                    />
                                </Grid>
                            </Grid>

                            <TextField
                                fullWidth
                                label="Durée (en minutes)"
                                type="number"
                                value={formData.duration}
                                onChange={(e) => setFormData({ ...formData, duration: parseInt(e.target.value) })}
                                sx={{ mb: 3 }}
                            />

                            <Box>
                                <Typography variant="subtitle2" sx={{ mb: 1 }}>Produits</Typography>
                                <Select
                                    options={productsData?.getAllProducts.map(p => ({
                                        value: p.id,
                                        label: p.name
                                    }))}
                                    isMulti
                                    value={formData.produits.map(id => {
                                        const product = productsData?.getAllProducts.find(p => p.id === id);
                                        return { value: id, label: product?.name };
                                    })}
                                    onChange={(selected) => setFormData({
                                        ...formData,
                                        produits: selected.map(option => option.value)
                                    })}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ p: 3 }}>
                        <Button onClick={() => setModalOpen(false)}>Annuler</Button>
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            color="primary"
                        >
                            {editingId ? 'Mettre à jour' : 'Créer'}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Delete Confirmation Dialog */}
                <Dialog
                    open={deleteDialogOpen}
                    onClose={() => setDeleteDialogOpen(false)}
                >
                    <DialogTitle>
                        Confirmer la suppression
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Cette action est irréversible. Êtes-vous sûr de vouloir supprimer cette routine ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ p: 2 }}>
                        <Button onClick={() => setDeleteDialogOpen(false)}>
                            Annuler
                        </Button>
                        <Button
                            onClick={handleDeleteConfirmation}
                            color="error"
                            variant="contained"
                        >
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Empty state */}
                {!loading && filteredRoutines.length === 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: '50vh',
                            textAlign: 'center',
                            color: 'text.secondary'
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Aucune routine trouvée
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            {filter ?
                                'Essayez de modifier vos critères de recherche.' :
                                'Commencez par créer votre première routine.'
                            }
                        </Typography>
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => {
                                resetForm();
                                setModalOpen(true);
                            }}
                        >
                            Créer une routine
                        </Button>
                    </Box>
                )}

                {/* Total count */}
                {filteredRoutines.length > 0 && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 3,
                            mb: 2,
                            px: 2,
                            py: 1,
                            backgroundColor: alpha(theme.palette.primary.main, 0.05),
                            borderRadius: 1,
                        }}
                    >
                        <Typography color="text.secondary">
                            {`Total : ${filteredRoutines.length} routine${filteredRoutines.length > 1 ? 's' : ''}`}
                        </Typography>
                        <Typography color="text.secondary">
                            {`Page ${currentPage} sur ${Math.ceil(filteredRoutines.length / itemsPerPage)}`}
                        </Typography>
                    </Box>
                )}
            </div>


        </div>
    );
};

export default RoutineDashboard;