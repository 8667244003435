import React from 'react';
import '../../styles/Footer.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    
    return (
        <footer className="footer" role="contentinfo">
            <p>
                © {currentYear} Groomed Hair
                <span className="separator" style={{ margin: '0 8px' }}>|</span>
                <span className="rights">Tous droits réservés</span>
            </p>
        </footer>
    );
};

export default Footer;