import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
    List,
    ListItem,
    CircularProgress,
    Paper,
    Box,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Typography,
    Alert,
    Snackbar,
    useTheme,
    Badge,
    Fade,
    IconButton,
    Toolbar,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigate } from "react-router-dom";
import { ALL_CONVERSATIONS_QUERY } from "../../graphql/queries";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
    maxWidth: 900,
    // margin: '20px auto',
    // borderRadius: '15px',
    overflow: 'hidden',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 6px 25px rgba(0, 0, 0, 0.15)',
    }
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.divider}`,
    transition: 'all 0.3s ease',
    minHeight: '100px',
    '&:hover': {
        backgroundColor: theme.palette.mode === 'light'
            ? 'rgba(0, 0, 0, 0.04)'
            : 'rgba(255, 255, 255, 0.08)',
        transform: 'scale(1.01)',
    },
    '&:last-child': {
        borderBottom: 'none',
    }
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    width: 50,
    height: 50,
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        transform: 'scale(1.1)',
    }
}));

const LoadingContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 400,
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
}));

const NoMessagesContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    '& svg': {
        fontSize: 48,
        color: theme.palette.text.disabled,
        marginBottom: theme.spacing(2),
    }
}));

const Messagerie = ({ onSelectConversation }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [authHeader, setAuthHeader] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [showError, setShowError] = useState(false);
    const [userMongoId, setUserMongoId] = useState(localStorage.getItem('userMongoId'));


    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            setAuthHeader(`Bearer ${token}`);
        } else {
            setErrorMessage("Utilisateur non authentifié");
            setShowError(true);
        }
    }, []);

    const { loading, error, data } = useQuery(ALL_CONVERSATIONS_QUERY, {
        context: {
            headers: {
                authorization: authHeader,
            },
        },
        skip: !authHeader,
        pollInterval: 5000,
        onError: () => {
            setErrorMessage("Erreur lors du chargement des conversations");
            setShowError(true);
        },
    });

    const getSortedConversations = (conversations) => {
        if (!conversations || !Array.isArray(conversations)) {
            return [];
        }

        return [...conversations].filter(conversation => {
            // Vérifier si l'utilisateur connecté fait partie des participants
            const isConnectedUser = conversation.participants.some(
                participant => participant.mongoId === userMongoId
            );

            // Vérifier si un utilisateur ("role": "user") est présent dans la conversation
            const hasUserParticipant = conversation.participants.some(
                participant => participant.role === 'user'
            );

            return isConnectedUser && hasUserParticipant;
        }).sort((a, b) => {
            try {
                const lastMessageA = a.messages[a.messages.length - 1];
                const lastMessageB = b.messages[b.messages.length - 1];

                if (!lastMessageA && !lastMessageB) return 0;
                if (!lastMessageA) return 1;
                if (!lastMessageB) return -1;

                const timestampA = parseInt(lastMessageA.timestamp);
                const timestampB = parseInt(lastMessageB.timestamp);

                if (isNaN(timestampA) || isNaN(timestampB)) {
                    console.warn('Invalid timestamp found in messages');
                    return 0;
                }

                return timestampB - timestampA;
            } catch (error) {
                console.error('Error sorting conversations:', error);
                return 0;
            }
        });
    };

    const handleSelectConversation = (conversation) => {
        onSelectConversation(conversation);
        // if (!user) {
        //     console.error("Invalid user data:", user);
        //     return;
        // }

        // navigate(`/discussion/${conversationId}`, {
        //     state: {
        //         conversationId,
        //         userName: `${user.firstName} ${user.lastName}`,
        //     },
        // });
    };

    if (loading) {
        return (
            <LoadingContainer>
                <Fade in={true} style={{ transitionDelay: '800ms' }}>
                    <CircularProgress />
                </Fade>
            </LoadingContainer>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 2 }}>
                <Toolbar />
                <Alert
                    severity="error"
                    variant="filled"
                    sx={{ borderRadius: 2 }}
                >
                    Une erreur est survenue lors du chargement des messages
                </Alert>
            </Box>
        );
    }

    const sortedConversations = getSortedConversations(data?.allConversations);

    if (!sortedConversations.length) {
        return (
            <NoMessagesContainer>
                <Typography variant="h6" gutterBottom>
                    Aucune conversation
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    Commencez une nouvelle conversation !
                </Typography>
            </NoMessagesContainer>
        );
    }

    return (
        <StyledPaper>
            <List disablePadding>
            {sortedConversations.map((conversation) => {
                    const userParticipant = conversation.participants.find(
                        (participant) => participant.role === "user"
                    );

                    if (!userParticipant) return null;

                    return (
                        <Fade
                            key={conversation.mongoId}
                            in={true}
                            timeout={500}
                            style={{ transitionDelay: `${1 * 100}ms` }}
                        >
                            <StyledListItem
                                button
                                onClick={() => handleSelectConversation(conversation)}
                            >
                                <ListItemAvatar>
                                    <StyledAvatar
                                        src={userParticipant?.profileImage}
                                        alt={`${userParticipant?.firstName} ${userParticipant?.lastName}`}
                                    >
                                        {userParticipant?.firstName[0]}
                                    </StyledAvatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${userParticipant.firstName} ${userParticipant.lastName}`}
                                    secondary={
                                        conversation.messages[conversation.messages.length - 1]?.content ||
                                        "Pas de messages"
                                    }
                                />
                                <IconButton edge="end">
                                    <KeyboardArrowRightIcon />
                                </IconButton>
                            </StyledListItem>
                        </Fade>
                    );
                })}
            </List>
        </StyledPaper>
    );
};

export default Messagerie;
